import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginAlterno from "../views/LoginAlterno";
import SeleccionarContenido from "../views/SeleccionarFase";
import Pruebas from '../views/Pruebas';

// ==================== Vistas Frontend CARING ==================== 
import Home from "../views/Caring/Home";
import Broshure from "../views/Caring/Broshure";
import BroshureFree from "../views/Caring/BroshureFree";
import Pilares from "../views/Caring/Pilares";
import Testimonios from "../views/Caring/Testimonios";
import Kickoff from "../views/Caring/Kickoff";
import Certificado from "../views/Caring/Certificado";


// pilar inclusion
import InclucionMasterClass from '../views/Caring/Pilares/Inclusion/MasterClass'
import InclucionVideoTutorial from '../views/Caring/Pilares/Inclusion/VideoTutorial';
import InclucionPodcast from '../views/Caring/Pilares/Inclusion/Podcast';
import InclucionCertificado from '../views/Caring/Pilares/Inclusion/Certificado';

// pilar gestion
import GestionMasterClass from '../views/Caring/Pilares/Gestion/MasterClass'
import GestionVideoTutorial from '../views/Caring/Pilares/Gestion/VideoTutorial';
import GestionPodcast from '../views/Caring/Pilares/Gestion/Podcast';
import GestionCertificado from '../views/Caring/Pilares/Gestion/Certificado';

// ==================== Vistas Frontend CARING ==================== 

// ==================== Vistas Frontend DARING ==================== 
import HomeDaring from '../views/Daring/Home';
import OyentePodcast from '../views/Daring/PilaresDaring/Oyente/Podcast';
import OyenteTutorial from '../views/Daring/PilaresDaring/Oyente/Tutorial';
import OyenteEvaluacion from '../views/Daring/PilaresDaring/Oyente/Evaluacion';
import OyenteInsignia from '../views/Daring/PilaresDaring/Oyente/Insignia';

import NarradorPodcast from '../views/Daring/PilaresDaring/Narrador/Podcast';
import NarradorTutorial from '../views/Daring/PilaresDaring/Narrador/Tutorial';
import NarradorEvaluacion from '../views/Daring/PilaresDaring/Narrador/Evaluacion';
import NarradorInsignia from '../views/Daring/PilaresDaring/Narrador/Insignia';

import TransformadorPodcast from '../views/Daring/PilaresDaring/Transformador/Podcast';
import TransformadorTutorial from '../views/Daring/PilaresDaring/Transformador/Tutorial';
import TransformadorEvaluacion from '../views/Daring/PilaresDaring/Transformador/Evaluacion';
import TransportadorInsignia from '../views/Daring/PilaresDaring/Transformador/Insignia';


// ==================== Vistas Frontend DARING ==================== 

// Vistas Back end
import LoginAdministrador from "../views/Adminstration/Login";
import HomeAdministrador from "../views/Adminstration/Home";
import Usuarios from '../views/Adminstration/Usuarios';
import UsuariosCrear from '../views/Adminstration/Usuarios/Crear';
import UsuariosActualizar from '../views/Adminstration/Usuarios/Actualizar';
import UsuariosActualizacion from '../views/Adminstration/Usuarios/Actualizacion';
import TestimoniosPanel from '../views/Adminstration/Testimonios';
import TestimoniosCrear from '../views/Adminstration/Testimonios/Crear';
import TestimoniosActualizar from '../views/Adminstration/Testimonios/Actualizar';
import MasterClass from '../views/Caring/MasterClass';
import VideoTutorial from '../views/Caring/VideoTutorial';
import Podcast from '../views/Caring/Podcast';
// import Cursos from "../views/Cursos";
// import AboutUs from "../views/AboutUS";
// import Speakers from "../views/Speakers";
// import StreamingOnLine from "../views/StreamingOnLine";
// import Adminstration from "../views/Adminstration";
// import Pruebas from "../views/Pruebas";


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<LoginAlterno />} />
        <Route exact path="/seleccionar-contenido" element={<SeleccionarContenido />} />
        <Route exact path="/pruebas" element={<Pruebas />} />

        {/* Frontend CARING */}
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/conoce-mas" element={<Broshure />} />
        <Route exact path="/acerca-de" element={<BroshureFree />} />
        <Route exact path="/pilares" element={<Pilares />} />
        <Route exact path="/testimonios" element={<Testimonios />} />
        <Route exact path="/kickoff" element={<Kickoff />} />
        <Route exact path="/master-class/:id" element={<MasterClass />} />
        <Route exact path="/tutorial/:id" element={<VideoTutorial />} />
        <Route exact path="/podcast/:id" element={<Podcast />} />
        <Route exact path="/evaluacion/:id/:perfil" element={<Certificado />} />

        {/* pilar inclusion */}
        <Route exact path="/master-class/:id/inclusion" element={<InclucionMasterClass />} />
        <Route exact path="/tutorial/:id/inclusion" element={<InclucionVideoTutorial />} />
        <Route exact path="/podcast/:id/inclusion" element={<InclucionPodcast />} />
        <Route exact path="/evaluacion/:id/inclusion" element={<InclucionCertificado />} />

        {/* pilar gestion */}
        <Route exact path="/master-class/:id/gestion" element={<GestionMasterClass />} />
        <Route exact path="/tutorial/:id/gestion" element={<GestionVideoTutorial />} />
        <Route exact path="/podcast/:id/gestion" element={<GestionPodcast />} />
        <Route exact path="/evaluacion/:id/gestion" element={<GestionCertificado />} />

        {/* Frontend DARING */}
        <Route exact path="/daring/home" element={<HomeDaring />} />
        <Route exact path="/daring/:id/oyente/tutorial" element={<OyenteTutorial />} />
        <Route exact path="/daring/:id/oyente/podcast" element={<OyentePodcast />} />
        <Route exact path="/daring/:id/oyente/evaluacion" element={<OyenteEvaluacion />} />
        <Route exact path="/daring/:id/oyente/insignia" element={<OyenteInsignia />} />

        <Route exact path="/daring/:id/narrador/tutorial" element={<NarradorTutorial />} />
        <Route exact path="/daring/:id/narrador/podcast" element={<NarradorPodcast />} />
        <Route exact path="/daring/:id/narrador/evaluacion" element={<NarradorEvaluacion />} />
        <Route exact path="/daring/:id/narrador/insignia" element={<NarradorInsignia />} />

        <Route exact path="/daring/:id/transformador/tutorial" element={<TransformadorTutorial />} />
        <Route exact path="/daring/:id/transformador/podcast" element={<TransformadorPodcast />} />
        <Route exact path="/daring/:id/transformador/evaluacion" element={<TransformadorEvaluacion />} />
        <Route exact path="/daring/:id/transformador/insignia" element={<TransportadorInsignia />} />


        {/* Back end⁄ */}
        <Route exact path="/admin/login" element={<LoginAdministrador />} />
        <Route exact path="/admin/dashboard" element={<HomeAdministrador />} />
        <Route exact path="/admin/usuarios" element={<Usuarios />} />
        <Route exact path="/admin/usuarios/crear" element={<UsuariosCrear />} />
        <Route exact path="/admin/usuarios/:id/actualizar" element={<UsuariosActualizar />} />
        <Route exact path="/admin/usuarios/carga" element={<UsuariosActualizacion />} />
        <Route exact path="/admin/testimonios" element={<TestimoniosPanel />} />
        <Route exact path="/admin/testimonios/crear" element={<TestimoniosCrear />} />
        <Route exact path="/admin/testimonios/:id/actualizar" element={<TestimoniosActualizar />} />
      </Routes>
    </BrowserRouter>
  );
}
