import React, { useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Grid, Container } from "@material-ui/core";
import Bienestar from './Bienestar';
import Inclusion from './Inclusion';
import Gestion from './Gestion';
import Comportamientos from './Comportamientos';
import btn1 from '../../../../../resources/images/broshure/seccion11/btn-bienestar.svg';
import btn1Off from '../../../../../resources/images/broshure/seccion11/btn-bienestar-off.svg';
import btn2 from '../../../../../resources/images/broshure/seccion11/btn-inclusion.svg';
import btn2Off from '../../../../../resources/images/broshure/seccion11/btn-inclusion-off.svg';
import btn3 from '../../../../../resources/images/broshure/seccion11/btn-gestion.svg';
import btn3Off from '../../../../../resources/images/broshure/seccion11/btn-gestion-off.svg';
import btn4 from '../../../../../resources/images/broshure/seccion11/btn-comportamientos.svg';
import btn4Off from '../../../../../resources/images/broshure/seccion11/btn-comportamientos-off.svg';


// botones mobile
import btn1Mobile from '../../../../../resources/images/broshure/seccion11/btn1-mobile.svg';
import btn2Mobile from '../../../../../resources/images/broshure/seccion11/btn2-mobile.svg';
import btn3Mobile from '../../../../../resources/images/broshure/seccion11/btn3-mobile.svg';
import btn4Mobile from '../../../../../resources/images/broshure/seccion11/btn4-mobile.svg';


export default function Seccion11() {
    const [cualSeccion, setCualSeccion] = useState(1);
    const [btnBienestar, setBtnBienestar] = useState(true);
    const [btnInclusion, setBtnInclusion] = useState(false);
    const [btnGestion, setBtnGestion] = useState(false);
    const [btnComportamientos, setBtnComportamientos] = useState(false);

    const cualBoton = id => {
        setCualSeccion(id);
        switch (id) {
            case 1:
                setBtnBienestar(true);
                setBtnInclusion(false);
                setBtnGestion(false);
                setBtnComportamientos(false);
                break;
            case 2:
                setBtnBienestar(false);
                setBtnInclusion(true);
                setBtnGestion(false);
                setBtnComportamientos(false);
                break;
            case 3:
                setBtnBienestar(false);
                setBtnInclusion(false);
                setBtnGestion(true);
                setBtnComportamientos(false);
                break;
            case 4:
                setBtnBienestar(false);
                setBtnInclusion(false);
                setBtnGestion(false);
                setBtnComportamientos(true);
                break;

            default:
                break;
        }
    }

    return (
        <>
            <section id="seccion11">
                <Container maxWidth="lg" >
                    <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
                        
                        <Grid item xs={12} sm={12} md={6} lg={6} className='wow slideInUp' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                            <div className='center titulo'>Da clic en cada uno para conocer más</div>
                        </Grid>
                    </Grid>
                </Container>
                {!isMobileOnly && <Container maxWidth="lg" >
                    <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
                        <Grid item xs={12} sm={6} md={4} lg={3} className='center wow zoomIn' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                            <div className='container-btns' onClick={() => cualBoton(1)}>
                                <img src={btnBienestar ? btn1 : btn1Off} alt="btn" />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} className='center wow zoomIn' data-wow-delay='.4s' data-wow-offset='100' data-wow-duration='.5s'>
                            <div className='container-btns' onClick={() => cualBoton(2)}>
                                <img src={btnInclusion ? btn2 : btn2Off} alt="btn" />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} className='center wow zoomIn' data-wow-delay='.6s' data-wow-offset='100' data-wow-duration='.5s'>
                            <div className='container-btns' onClick={() => cualBoton(3)}>
                                <img src={btnGestion ? btn3 : btn3Off} alt="btn" />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} className='center wow zoomIn' data-wow-delay='.8s' data-wow-offset='100' data-wow-duration='.5s'>
                            <div className='container-btns' onClick={() => cualBoton(4)}>
                                <img src={btnComportamientos ? btn4 : btn4Off} alt="btn" />
                            </div>
                        </Grid>
                    </Grid>
                </Container>}
                {isMobileOnly && <Container maxWidth="lg" >
                    <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
                        <Grid item xs={3} className='center wow zoomIn' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                            <div className={`container-btns ${btnBienestar && 'border'}`} onClick={() => cualBoton(1)}>
                                <img src={btn1Mobile} alt="btn" className='full' />
                            </div>
                        </Grid>
                        <Grid item xs={3} className='center wow zoomIn' data-wow-delay='.4s' data-wow-offset='100' data-wow-duration='.5s'>
                            <div className={`container-btns ${btnInclusion && 'border'}`} onClick={() => cualBoton(2)}>
                                <img src={btn2Mobile} alt="btn" className='full' />
                            </div>
                        </Grid>
                        <Grid item xs={3} className='center wow zoomIn' data-wow-delay='.6s' data-wow-offset='100' data-wow-duration='.5s'>
                            <div className={`container-btns ${btnGestion && 'border'}`} onClick={() => cualBoton(3)}>
                                <img src={btn3Mobile} alt="btn" className='full' />
                            </div>
                        </Grid>
                        <Grid item xs={3} className='center wow zoomIn' data-wow-delay='.8s' data-wow-offset='100' data-wow-duration='.5s'>
                            <div className={`container-btns ${btnComportamientos && 'border'}`} onClick={() => cualBoton(4)}>
                                <img src={btn4Mobile} alt="btn" className='full' />
                            </div>
                        </Grid>
                    </Grid>
                </Container>}
            </section>
            {cualSeccion === 1 && <Bienestar />}
            {cualSeccion === 2 && <Inclusion />}
            {cualSeccion === 3 && <Gestion />}
            {cualSeccion === 4 && <Comportamientos />}
        </>
    )
}