import React from 'react';
import { Grid, Container } from "@material-ui/core";
import img1 from '../../../../../resources/images/broshure/seccion14/img1.svg';
import img2 from '../../../../../resources/images/broshure/seccion14/img2.svg';
import img3 from '../../../../../resources/images/broshure/seccion14/img3.svg';
import img4 from '../../../../../resources/images/broshure/seccion14/img4.svg';

export default function Seccion14() {

    return <section id="seccion14">
        <Container maxWidth="lg" >
            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={1}>
                <Grid item xs={6} sm={5} md={5} lg={5} className='center wow flipInY' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                    <div className="center">
                        <img src={img1} alt="img" className='full mb30' />
                    </div>
                </Grid>
                <Grid item xs={6} sm={5} md={5} lg={5} className='center wow flipInY' data-wow-delay='.4s' data-wow-offset='100' data-wow-duration='.5s'>
                    <div className="center">
                        <img src={img2} alt="img" className='full mb30' />
                    </div>
                </Grid>
            </Grid>
        </Container>
        <Container maxWidth="lg" >
            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={1}>
                <Grid item xs={6} sm={5} md={5} lg={5} className='center wow flipInY' data-wow-delay='.6s' data-wow-offset='100' data-wow-duration='.5s'>
                    <div className="center">
                        <img src={img3} alt="img" className='full' />
                    </div>
                </Grid>
                <Grid item xs={6} sm={5} md={5} lg={5} className='center wow flipInY' data-wow-delay='.8s' data-wow-offset='100' data-wow-duration='.5s'>
                    <div className="center">
                        <img src={img4} alt="img" className='full' />
                    </div>
                </Grid>
            </Grid>
        </Container>
    </section>
}