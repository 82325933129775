import React, { useState, useEffect, createRef } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Container, Button, Hidden } from "@material-ui/core";
import Pdf from "react-to-pdf";
import { useScreenshot, createFileName } from 'use-react-screenshot';
import Header from '../../../../components/HeaderDaring';
import Footer from '../../../../components/FooterDaring';
import { getCollectionsComplete, getDate, searchDataByValue, top, updateData } from "../../../../resources/js/functions";
import certificado from "../../../../resources/images/daring/pilares/certificado2.svg"
import "./styles.scss";

export default function Certificate() {
  const [user, setUser] = useState({});
  const [left, setLeft] = useState(30);
  const ref = React.createRef();
  const reference = createRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    getUser();
    vistaDeVisualizacion();
  }, []);

  const getUser = async () => {
    const usuario = await searchDataByValue("users", "id", localStorage.getItem("0hNZeY/gjEeJihP9bIPGHw=="));
    if (usuario.nombre.length > 30) {
      setLeft(26);
    }
    if (usuario.nombre.length > 9 && usuario.nombre.length < 18) {
      setLeft(40);
    }
    // console.log(usuario);
    setUser(usuario);
  };
  
  const vistaDeVisualizacion = async () => {
    let json = {}
    const data = await getCollectionsComplete(`daring/masterclass/${localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw==')}`);
    if (Object.keys(data).length === 0) {
      json = {
        fechaDeMasterClass: getDate(),
        masterclass: true
      }
      updateData(`daring/masterclass`, localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), json).then(() => { }).catch(e => console.log(e))
    }
    updateData(`daring/reconocimiento`, localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { descargaReconocimiento: true, fechaDescargaReconocimiento: getDate() }).then(() => { console.log("entra");}).catch(e => console.log(e))
  }

  const [image, takeScreenshot] = useScreenshot({
    type: "image/png",
    quality: 1.0
  });

  const download = (image, { name = "certificado", extension = "png" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const getImage = async () => {
    let a = reference.current;
    setTimeout(async () => {
      await takeScreenshot(a).then(download);
      // setShow(false)
    }, 1000);
  }

  const updateDescarga = async () => {
    // let json = {}
    // const data = await getCollectionsComplete(`daring/masterclass/${localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw==') }`);
    // if (Object.keys(data).length === 0) {
    //   json = {
    //     fechaDeMasterClass: getDate(),
    //     masterclass: true
    //   }
    //   updateData(`daring/masterclass`, localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), json).then(() => {}).catch(e => console.log(e))
    // }
    // updateData(`daring/reconocimiento`, localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { descargaReconocimiento: true, fechaDescargaReconocimiento: getDate() }).then(() => {
    getImage();
    // }).catch(e => console.log(e))
  }

  return (
    <>
      <div id="insignia">
        <Container maxWidth="lg">
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>

            <Grid item lg={11} md={11} sm={12} xs={12}>
              <p className="text">
                Descarga tu reconocimiento y consérvalo. Para obtenerlo en la mejor calidad posible, te recomendamos hacerlo desde un equipo de escritorio.
                Si no tienes cómo hacerlo ahora mismo, no te preocupes, siempre puedes volver a ingresar para descargar el reconocimiento entrando en esta sección.
              </p>
            </Grid>
            <Grid item lg={11} md={11} sm={12} xs={12}>
              <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
                <Grid item lg={10} md={6} sm={12} xs={12}>
                  <div className="titulos">¡Felicidades!</div>
                </Grid>
                <Grid item lg={2} md={6} sm={12} xs={12} className='mt30'>
                  <Button className="btn-azul" onClick={() => { updateDescarga() }}>
                    Descargar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={11} md={11} sm={12} xs={12} style={{ background: "#F8F7F7" }} ref={reference}>
              <div className="center full">
                <div id="imgCertificado" className="certificate">
                  <img src={certificado} alt="as" className="full" />
                  <div className="name" style={{ left: `${left}%` }}>{`${user.nombre}`}</div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <br />
    </>
  );
}
