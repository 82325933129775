import React, { useState, useEffect } from "react";
import { db } from "../../config/firebase";
import { ref, onChildChanged } from "firebase/database";
import { getCollections } from "../../resources/js/functions";
import "./style.scss";

export default function Buscar(props) {
  const { busquedaProps } = props;
  const [buscar, setBuscar] = useState("");
  const [conferencias, setConferencias] = useState([]);
  let filteredConferencias = [];
  const excludeColumns = [];

  useEffect(() => {
    getUsers();
    const dbRef = ref(db, "users/");
    onChildChanged(dbRef, (data) => {
      getUsers();
    });
  }, []);

  const getUsers = async () => {
    const conferencias = await getCollections("users/");
    setConferencias(conferencias.filter(val => val.activo === true));
  };

  async function buscarChange(e) {
    const lowercasedValue = e.target.value.toLowerCase()
    setBuscar(lowercasedValue);
    if (e.target.value.length < 3) {
      const conferencias = await getCollections("users/");
      const value = Object.values(conferencias);
      busquedaProps(value);
    } else {
      const filteredData = conferencias.filter(item => {
        return Object.keys(item).some(key =>
          excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      if (Object.keys(filteredData).length < 1 || buscar.length < 2) {
        busquedaProps([]);
      } else {
        busquedaProps(filteredData);
      }
    }
  }

  return (
    <>
      <div className="input-icons">
        <i className="fa fa-search icon"></i>
        <input
          onBlur={Object.keys(filteredConferencias).length < 1 ? () => getUsers() : () => busquedaProps(filteredConferencias)}
          onPaste={e => console.log(e.currentTarget.value)}
          type="text"
          className="input-search"
          placeholder="Escribe el número, nombre o ID global del empleado para buscarlo..."
          value={buscar}
          onChange={buscarChange}
        />
      </div>
    </>
  );
}
