import React from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { ejectutivos, jefaturasyCoordinadores } from '../../informacion';

export default function SpeakersConfertencias() {
  const params = useParams();

  return (
    <>
      <Grid item lg={8} md={12} xs={12}>
        <div className="titulos">Acerca del experto</div>
      </Grid>
      <Grid item lg={8} md={12} xs={12} >
        <div className="full">
          <div className="name-conferencista full ">
            <div >
              <img className="img-conferencista" src={params.id === 'ejecutivos' ? ejectutivos.masterClass.imgExperto : jefaturasyCoordinadores.masterClass.imgExperto} alt="img" />
            </div>
            <span className="tendencias bold" style={{ display: "inline-block" }}>
              <span className="job-conferencista">
                <span className="name">{params.id === 'ejecutivos' ? ejectutivos.masterClass.experto : jefaturasyCoordinadores.masterClass.experto}</span>
                <span className="description-conferencista">
                  {
                    params.id === 'ejecutivos' ? ejectutivos.masterClass.descripcionExperto : jefaturasyCoordinadores.masterClass.descripcionExperto
                  }
                </span>
              </span>
            </span>
          </div>
        </div>
      </Grid>
    </>
  );
}





