import { getCollections, cambiarTextos, getDate } from '../../../resources/js/functions';
let xlsx = require('json-as-xlsx');

export async function usuariosBD(nombre = 'Usuarios') {
	let array = [];
	const results = await getCollections('users');
	Object.values(results).map((val) => {
		if (val.id) {
			// if (val.id !== '000000' && val.id !== '111111') {
			array.push({ ...val, activo: val.activo ? 'Activo' : 'Inactivo' });
			// }
		}
	});
	let data = [
		{
			sheet: 'Usuarios',
			columns: [
				{ label: 'Número de empleado', value: 'id' },
				{ label: 'ID Global', value: 'idGlobal' },
				{ label: 'Nombre', value: 'nombre' },
				{ label: 'Puesto', value: 'puesto' },
				{ label: 'Nombre Completo del Line Manager', value: 'nombreLineManager' },
				{ label: 'Audiencia SPIRA CONNECT', value: 'audiencia' },
				{ label: 'Estatus Line Manager', value: 'statusLineManager' },
				{ label: 'Correo', value: 'correo' },
				{ label: 'J1', value: 'j1' },
				{ label: 'J2', value: 'j2' },
				{ label: 'J3', value: 'j3' },
				{ label: 'J4', value: 'j4' },
				{ label: 'J5', value: 'j5' },
				{ label: 'Activo', value: 'activo' },
				{ label: 'Fecha de regitro', value: 'fechaRegistro' },
			],
			content: array,
		},
	];

	let settings = {
		fileName: `${nombre}`,
		extraLength: 3,
		writeOptions: {},
	};

	xlsx(data, settings);
}

export async function formato(nombre = 'Formato-de-cargaaaaa') {
	let array = [];
	const results = await getCollections('users');
	Object.values(results).map((val) => {
		if (val.id) {
			// if (val.id !== '000000' && val.id !== '111111') {
			array.push({ ...val, activo: val.activo ? 'Activo' : 'Inactivo' });
			// }
		}
	});
	let data = [
		{
			sheet: 'Usuarios',
			columns: [
				{ label: 'Número de empleado', value: 'id' },
				{ label: 'ID Global', value: 'idGlobal' },
				{ label: 'Nombre', value: 'nombre' },
				{ label: 'Puesto', value: 'puesto' },
				{ label: 'Nombre Completo del Line Manager', value: 'nombreLineManager' },
				{ label: 'Audiencia SPIRA CONNECT', value: 'audiencia' },
				{ label: 'Estatus Line Manager', value: 'statusLineManager' },
				{ label: 'Correo', value: 'correo' },
				{ label: 'J1', value: 'j1' },
				{ label: 'J2', value: 'j2' },
				{ label: 'J3', value: 'j3' },
				{ label: 'J4', value: 'j4' },
				{ label: 'J5', value: 'j5' },
				{ label: 'Activo', value: 'activo' },
				{ label: 'Fecha de regitro', value: 'fechaRegistro' },
			],
			content: array,
		},
	];

	let settings = {
		fileName: `${nombre}`,
		extraLength: 3,
		writeOptions: {},
	};

	xlsx(data, settings);
}

export async function reporteTodos() {
	let array = [];
	const results = await getCollections('users');
	Object.values(results).map((val) => {
		if (val.id) {
			if (val.id !== '000000' && val.id !== '111111') {
				array.push(val);
			}
		}
	});
	const fecha = cambiarTextos(getDate());
	let data = [
		{
			sheet: 'Usuarios',
			columns: [
				{ label: 'Número de empleado', value: 'id' },
				{ label: 'ID Global', value: 'idGlobal' },
				{ label: 'Nombre', value: 'nombre' },
				{ label: 'Puesto', value: 'puesto' },
				{ label: 'Nombre Completo del Line Manager', value: 'nombreLineManager' },
				{ label: 'Audiencia SPIRA CONNECT', value: 'audiencia' },
				{ label: 'Estatus Line Manager', value: 'statusLineManager' },
				{ label: 'Correo', value: 'correo' },
				{ label: 'J1', value: 'j1' },
				{ label: 'J2', value: 'j2' },
				{ label: 'J3', value: 'j3' },
				{ label: 'J4', value: 'j4' },
				{ label: 'J5', value: 'j5' },
				{ label: 'Ultima conexón', value: 'ultimaConexion' },
			],
			content: array,
		},
	];

	let settings = {
		fileName: `Reporte-de-todos-los-usuarios-${fecha}`,
		extraLength: 3,
		writeOptions: {},
	};

	xlsx(data, settings);
}

export async function reporteActivos() {
	let array = [];
	const results = await getCollections('users');
	Object.values(results).map((val) => {
		if (val.id && val.activo) {
			array.push(val);
		}
	});
	const fecha = cambiarTextos(getDate());
	let data = [
		{
			sheet: 'Usuarios',
			columns: [
				{ label: 'Número de empleado', value: 'id' },
				{ label: 'ID Global', value: 'idGlobal' },
				{ label: 'Nombre', value: 'nombre' },
				{ label: 'Puesto', value: 'puesto' },
				{ label: 'Nombre Completo del Line Manager', value: 'nombreLineManager' },
				{ label: 'Audiencia SPIRA CONNECT', value: 'audiencia' },
				{ label: 'Estatus Line Manager', value: 'statusLineManager' },
				{ label: 'Correo', value: 'correo' },
				{ label: 'J1', value: 'j1' },
				{ label: 'J2', value: 'j2' },
				{ label: 'J3', value: 'j3' },
				{ label: 'J4', value: 'j4' },
				{ label: 'J5', value: 'j5' },
			],
			content: array,
		},
	];

	let settings = {
		fileName: `usuarios-activos-${fecha}`,
		extraLength: 3,
		writeOptions: {},
	};

	xlsx(data, settings);
}

export async function reporteInactivos() {
	let array = [];
	const results = await getCollections('users');
	Object.values(results).map((val) => {
		if (val.id && !val.activo) {
			array.push(val);
		}
	});
	const fecha = cambiarTextos(getDate());
	let data = [
		{
			sheet: 'Usuarios',
			columns: [
				{ label: 'Número de empleado', value: 'id' },
				{ label: 'ID Global', value: 'idGlobal' },
				{ label: 'Nombre', value: 'nombre' },
				{ label: 'Puesto', value: 'puesto' },
				{ label: 'Nombre Completo del Line Manager', value: 'nombreLineManager' },
				{ label: 'Audiencia SPIRA CONNECT', value: 'audiencia' },
				{ label: 'Estatus Line Manager', value: 'statusLineManager' },
				{ label: 'Correo', value: 'correo' },
				{ label: 'J1', value: 'j1' },
				{ label: 'J2', value: 'j2' },
				{ label: 'J3', value: 'j3' },
				{ label: 'J4', value: 'j4' },
				{ label: 'J5', value: 'j5' },
				{ label: 'Ultima conexón', value: 'ultimaConexion' },
			],
			content: array,
		},
	];

	let settings = {
		fileName: `usuarios-inactivos-${fecha}`,
		extraLength: 3,
		writeOptions: {},
	};

	xlsx(data, settings);
}
