import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { Grid, Container } from "@material-ui/core";
import Swal from "sweetalert2";
import { ref, onChildChanged } from "firebase/database";
import { db } from "../../../config/firebase";
import { download_file, updateData, searchDataByValue, getDate } from '../../../resources/js/functions'
import { ejectutivos, jefaturasyCoordinadores } from './informacion';
import InclusionBrochure from '../../Caring/Broshure/RolUno/Seccion11/Inclusion';
import visto from '../../../resources/images/pilares/bienestar/visto.svg';
import noVisto from '../../../resources/images/pilares/bienestar/no-visto.svg';
import scroll from '../../../resources/images/scroll.gif';
import likeOn from '../../../resources/images/pilares/like-on.svg';
import likeOff from '../../../resources/images/pilares/like-off.svg';
import dislikeOn from '../../../resources/images/pilares/dislike-on.svg';
import dislikeOff from '../../../resources/images/pilares/dislike-off.svg';

export default function Inclusion() {
    const navigate = useNavigate();
    const [audiencia, setAudiencia] = useState('Ejecutivos');
    const [recursosActivos, setRecursosActivos] = useState({
        masterClass: false,
        tutorial: false,
        podcast: false,
        playbook: false,
        evaluacion: false,
    });
    const [likes, setLikes] = useState({})
    // const [likes, setLikes] = useState({
    //     masterClass: {
    //         like: false,
    //         dislike: false,
    //     },
    //     tutorial: {
    //         like: false,
    //         dislike: false,
    //     },
    //     podcast: {
    //         like: false,
    //         dislike: false,
    //     },
    //     playbook: {
    //         like: false,
    //         dislike: false,
    //     },
    //     evaluacion: {
    //         like: false,
    //         dislike: false,
    //     }
    // })


    useEffect(() => {
        getRecursos();
        getInfoUSer();
        getlikes();
        const dbRef = ref(db, `pilarInclusion/`);
        onChildChanged(dbRef, (data) => {
            getRecursos();
        });
        const dbRefLikes = ref(db, `likes/inclusion/`);
        onChildChanged(dbRefLikes, (data) => {
            getlikes();
        });
    }, [])

    const getInfoUSer = async () => {
        const data = await searchDataByValue(`users`, 'id', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='));
        if (data) {
            setAudiencia(data.audiencia);
        }
    }

    const getRecursos = async () => {
        const data = await searchDataByValue(`pilarInclusion`, 'id', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='));
        let json = {
            masterClass: data.masterClass || false,
            tutorial: data.tutorial || false,
            podcast: data.podcast || false,
            playbook: data.playbook || false,
            evaluacion: data.evaluacion || false,
        }
        setRecursosActivos(json);
    }

    const irRecurso = id => {
        switch (id) {
            case 1:
                updateData('pilarInclusion', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { 'masterClass': true, 'id': localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), fechaVistoMasterClass: getDate() }).then(() => {
                    navigate(audiencia === 'Ejecutivos' ? ejectutivos.masterClass.url : jefaturasyCoordinadores.masterClass.url)
                }).catch(e => console.log(e));
                break;
            case 2:
                updateData('pilarInclusion', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { 'tutorial': true, 'id': localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), fechaVistoTutorial: getDate() }).then(() => {
                    navigate(audiencia === 'Ejecutivos' ? ejectutivos.tutorial.url : jefaturasyCoordinadores.tutorial.url)
                }).catch(e => console.log(e));
                // modalMasterClass();
                break;
            case 3:
                updateData('pilarInclusion', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { 'podcast': true, 'id': localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), fechaVistoPodcast: getDate() }).then(() => {
                    navigate(audiencia === 'Ejecutivos' ? ejectutivos.podcast.url : jefaturasyCoordinadores.podcast.url)
                }).catch(e => console.log(e));
                break;
            case 4:
                updateData('pilarInclusion', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { 'playbook': true, 'id': localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), fechaVistoPlaybook: getDate() }).then(() => {
                    const url = audiencia === 'Ejecutivos' ? ejectutivos.playbook.urlPlaybook : jefaturasyCoordinadores.playbook.urlPlaybook;
                    download_file(url, 'playbook');
                }).catch(e => console.log(e));
                break;
            case 5:
                if (recursosActivos.masterClass && recursosActivos.tutorial && recursosActivos.podcast && recursosActivos.playbook) {
                    updateData('pilarInclusion', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { 'evaluacion': true, 'id': localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), fechaVistoEvaluacion: getDate() });
                    navigate(audiencia === 'Ejecutivos' ? ejectutivos.evaluacion.url : jefaturasyCoordinadores.evaluacion.url)
                } else {
                    Swal.fire({
                        title: "¡Espera!",
                        text: "Termina de consultar todos los recursos para acceder a la evaluación.",
                        icon: "warning",
                        confirmButtonText: "Cerrar",
                        confirmButtonColor: "#2D6DB4",
                    })
                }
                break;

            default:
                break;
        }
    }

    const validarMasterClassActivacion = () => {
        if (audiencia === 'Ejecutivos') {
            if (ejectutivos.masterClass.activo) {
                irRecurso(1)
            } else {
                modalMasterClass();
            }
        }
        if (audiencia === 'Jefaturas y Coordinaciones') {
            if (jefaturasyCoordinadores.masterClass.activo) {
                irRecurso(1)
            } else {
                modalMasterClass();
            }
        }
    }

    const modalMasterClass = () => {
        Swal.fire({
            title: "Espéralo próximamente",
            text: '',
            icon: "warning",
            confirmButtonText: "Cerrar",
            confirmButtonColor: "#2D6DB4",
        })
    }

    const getlikes = async () => {
        let json = {
            masterClass: {
                like: false,
                dislike: false,
            },
            tutorial: {
                like: false,
                dislike: false,
            },
            podcast: {
                like: false,
                dislike: false,
            },
            playbook: {
                like: false,
                dislike: false,
            },
            evaluacion: {
                like: false,
                dislike: false,
            }
        };
        const data = await searchDataByValue(`likes/inclusion/`, 'id', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='));
        if (Object.keys(data).length > 0) {
            if (data.masterClassfechaRegistro) {
                data.masterClass ? json['masterClass'].like = true : json['masterClass'].dislike = true
            }

            if (data.tutorialfechaRegistro) {
                data.tutorial ? json['tutorial'].like = true : json['tutorial'].dislike = true
            }

            if (data.podcastfechaRegistro) {
                if (data.podcast || !data.podcast) {
                    data.podcast ? json['podcast'].like = true : json['podcast'].dislike = true
                }
            }

            if (data.playbookfechaRegistro) {
                data.playbook ? json['playbook'].like = true : json['playbook'].dislike = true
            }
        }

        setLikes(json);


    }

    const setLikesBd = (recurso, boolean) => {
        switch (recurso) {
            case 'masterClass':
                updateData('likes/inclusion', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { [recurso]: boolean, id: localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), masterClassfechaRegistro: getDate() })
                break;
            case 'tutorial':
                updateData('likes/inclusion', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { [recurso]: boolean, id: localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), tutorialfechaRegistro: getDate() })
                break;
            case 'podcast':
                updateData('likes/inclusion', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { [recurso]: boolean, id: localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), podcastfechaRegistro: getDate() })
                break;
            case 'playbook':
                updateData('likes/inclusion', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { [recurso]: boolean, id: localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), playbookfechaRegistro: getDate() })
                break;
            case 'evaluacion':

                break;

            default:
                break;
        }
    }


    return (
        <>
            {/* <div id="inclusion">
                <Container maxWidth="lg">
                    <Grid container direction="row" alignItems="center" justifyContent='center' spacing={2}>
                        <Grid item xs={12} sm={12} md={1} lg={1} className='wow zoomInLeft center' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                            <img src={scroll} alt="scroll" className='scroll-gif' />
                        </Grid>
                    </Grid>
                </Container>
                <div id="recursos-digitales">
                    <InclusionBrochure />
                </div>
            </div> */}

            {/* ////////// desbloquear cuando se apruebe abrir este pilar y borrar todo el div comentado de arriva  */}
            <div style={{ backgroundColor: '#fff' }}>
                <Container maxWidth="lg" >
                    <Grid container direction="row" alignItems="center" justifyContent='center' spacing={2}>
                        <Grid item xs={12} sm={12} md={1} lg={1} className=' center'>
                            <img src={scroll} alt="scroll" className='scroll-gif' />
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div id="inclucion">
                <div id="video-introductorio">
                    <Container maxWidth="lg">
                        <Grid container direction="row" alignItems="center" justifyContent='center' spacing={2}>
                            <Grid item xs={12} sm={6} md={4} lg={4} className='wow zoomInLeft center' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                                <p>¿Sabes por qué es importante tu participación para consolidar en HEINEKEN una cultura de Inclusión, Diversidad y Equidad? Descúbrelo en este video.</p>
                            </Grid>
                            <Grid item xs={12} sm={6} md={5} lg={5} className='wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                                <div className="contenedor-streaming">
                                    <iframe title="streaming" src={audiencia === 'Ejecutivos' ? ejectutivos.videoIntoduccion : jefaturasyCoordinadores.videoIntoduccion} allow="autoplay; fullscreen" frameBorder="0" allowFullScreen className="estilo-streaming"></iframe>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <div id="recursos-digitales">
                    <Container maxWidth="lg">
                        <Grid container direction="row" alignItems="stretch" spacing={2}>
                            <Grid item xs={12} sm={12} md={10} lg={10} className='wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                                <h1>Recursos digitales. Date el tiempo necesario para revisarlos en su totalidad.</h1>
                            </Grid>
                        </Grid>
                    </Container>
                    <Container maxWidth="lg">
                        <Grid container direction="row" alignItems="center" justifyContent='center' spacing={2}>
                            <Grid item xs={12} sm={6} md={5} lg={5} className='center wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                                <div className="fullwrap" onClick={() => validarMasterClassActivacion()}>
                                    <img src={audiencia === 'Ejecutivos' ? ejectutivos.masterClass.portada : jefaturasyCoordinadores.masterClass.portada} alt="img" className='full cursor' />
                                    <div className="fullcap">
                                        <div className="titulo">
                                            {audiencia === 'Ejecutivos' ? ejectutivos.masterClass.titulo : jefaturasyCoordinadores.masterClass.titulo}
                                        </div>
                                        <div className="btn-caption btn-one">
                                            {audiencia === 'Ejecutivos' ? ejectutivos.masterClass.experto : jefaturasyCoordinadores.masterClass.experto}
                                        </div>
                                    </div>
                                </div>
                                <div className='full center'>
                                    <Grid container direction="row" alignItems="center" justifyContent='center' spacing={2}>
                                        <Grid item xs={12} sm={12} md={2} lg={2}>
                                            <img src={recursosActivos.masterClass ? visto : noVisto} alt="img" />
                                        </Grid>
                                        {recursosActivos.masterClass && <><Grid item xs={8} sm={8} md={7} lg={7}>
                                            <b>¿TE GUSTÓ ESTE CONTENIDO?</b>
                                        </Grid>
                                            <Grid item xs={4} sm={4} md={3} lg={3}>
                                                <div className='contenedor-likes'>
                                                    <img className='btn-likes' src={likes.masterClass.like ? likeOn : likeOff} alt="img" onClick={() => setLikesBd('masterClass', true)} />
                                                    <img className='btn-likes' src={likes.masterClass.dislike ? dislikeOn : dislikeOff} alt="img" onClick={() => setLikesBd('masterClass', false)} />
                                                </div>
                                            </Grid></>}
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={5} lg={5} className='center wow zoomInRight' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                                <div className="fullwrap" onClick={() => irRecurso(2)}>
                                    <img src={audiencia === 'Ejecutivos' ? ejectutivos.tutorial.portada : jefaturasyCoordinadores.tutorial.portada} alt="img" className='full cursor' />
                                    <div className="fullcap">
                                        <div className="titulo">
                                            {audiencia === 'Ejecutivos' ? ejectutivos.tutorial.titulo : jefaturasyCoordinadores.tutorial.titulo}
                                        </div>
                                        <div className="btn-caption btn-one">
                                            {audiencia === 'Ejecutivos' ? ejectutivos.tutorial.experto : jefaturasyCoordinadores.tutorial.experto}
                                        </div>
                                    </div>
                                </div>
                                <div className='full center'>
                                    <Grid container direction="row" alignItems="center" justifyContent='center' spacing={2}>
                                        <Grid item xs={12} sm={12} md={2} lg={2}>
                                            <img src={recursosActivos.tutorial ? visto : noVisto} alt="img" />
                                        </Grid>
                                        {recursosActivos.tutorial && <><Grid item xs={8} sm={8} md={7} lg={7}>
                                            <b>¿TE GUSTÓ ESTE CONTENIDO?</b>
                                        </Grid>
                                            <Grid item xs={4} sm={4} md={3} lg={3}>
                                                <div className='contenedor-likes'>
                                                    <img className='btn-likes' src={likes.tutorial.like ? likeOn : likeOff} alt="img" onClick={() => setLikesBd('tutorial', true)} />
                                                    <img className='btn-likes' src={likes.tutorial.dislike ? dislikeOn : dislikeOff} alt="img" onClick={() => setLikesBd('tutorial', false)} />
                                                </div>
                                            </Grid></>}
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={5} lg={5} className='center wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                                <div className="fullwrap" onClick={() => irRecurso(3)}>
                                    <img src={audiencia === 'Ejecutivos' ? ejectutivos.podcast.portada : jefaturasyCoordinadores.podcast.portada} alt="img" className='full cursor' />
                                    <div className="fullcap">
                                        <div className="titulo">
                                            {audiencia === 'Ejecutivos' ? ejectutivos.podcast.titulo : jefaturasyCoordinadores.podcast.titulo}
                                        </div>
                                        <div className="btn-caption btn-one">
                                            {audiencia === 'Ejecutivos' ? ejectutivos.podcast.experto : jefaturasyCoordinadores.podcast.experto}
                                        </div>
                                    </div>
                                </div>
                                <div className='full center'>
                                    <Grid container direction="row" alignItems="center" justifyContent='center' spacing={2}>
                                        <Grid item xs={12} sm={12} md={2} lg={2}>
                                            <img src={recursosActivos.podcast ? visto : noVisto} alt="img" />
                                        </Grid>
                                        {recursosActivos.podcast && <><Grid item xs={8} sm={8} md={7} lg={7}>
                                            <b>¿TE GUSTÓ ESTE CONTENIDO?</b>
                                        </Grid>
                                            <Grid item xs={4} sm={4} md={3} lg={3}>
                                                <div className='contenedor-likes'>
                                                    <img className='btn-likes' src={likes.podcast.like ? likeOn : likeOff} alt="img" onClick={() => setLikesBd('podcast', true)} />
                                                    <img className='btn-likes' src={likes.podcast.dislike ? dislikeOn : dislikeOff} alt="img" onClick={() => setLikesBd('podcast', false)} />
                                                </div>
                                            </Grid></>}
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={5} lg={5} className='center wow zoomInRight' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                                <div className="fullwrap" onClick={() => irRecurso(4)}>
                                    <img src={audiencia === 'Ejecutivos' ? ejectutivos.playbook.portada : jefaturasyCoordinadores.playbook.portada} alt="img" className='full cursor' />
                                    <div className="fullcap">
                                        <div className="titulo">
                                            {audiencia === 'Ejecutivos' ? ejectutivos.playbook.titulo : jefaturasyCoordinadores.playbook.titulo}
                                        </div>
                                        {ejectutivos.playbook.experto !== '' || jefaturasyCoordinadores.playbook.experto !== '' && <div className="btn-caption btn-one">
                                            {audiencia === 'Ejecutivos' ? ejectutivos.playbook.experto : jefaturasyCoordinadores.playbook.experto}
                                        </div>}
                                    </div>
                                </div>
                                <div className='full center'>
                                    <Grid container direction="row" alignItems="center" justifyContent='center' spacing={2}>
                                        <Grid item xs={12} sm={12} md={2} lg={2}>
                                            <img src={recursosActivos.playbook ? visto : noVisto} alt="img" />
                                        </Grid>
                                        {recursosActivos.playbook && <><Grid item xs={8} sm={8} md={7} lg={7}>
                                            <b>¿TE GUSTÓ ESTE CONTENIDO?</b>
                                        </Grid>
                                            <Grid item xs={4} sm={4} md={3} lg={3}>
                                                <div className='contenedor-likes'>
                                                    <img className='btn-likes' src={likes.playbook.like ? likeOn : likeOff} alt="img" onClick={() => setLikesBd('playbook', true)} />
                                                    <img className='btn-likes' src={likes.playbook.dislike ? dislikeOn : dislikeOff} alt="img" onClick={() => setLikesBd('playbook', false)} />
                                                </div>
                                            </Grid></>}
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                    <Container maxWidth="lg" style={{ marginTop: '30px' }}>
                        <Grid container direction="row" alignItems="center" justifyContent='center' spacing={2}>
                            <Grid item xs={12} sm={6} md={5} lg={5} className='center wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                                <div className="fullwrap" onClick={() => irRecurso(5)}>
                                    <img src={audiencia === 'Ejecutivos' ? ejectutivos.evaluacion.portada : jefaturasyCoordinadores.evaluacion.portada} alt="img" className='full cursor' />
                                    <div className="fullcap">
                                        <div className="titulo">
                                            {audiencia === 'Ejecutivos' ? ejectutivos.evaluacion.titulo : jefaturasyCoordinadores.evaluacion.titulo}
                                        </div>
                                    </div>
                                </div>
                                <div className='full center'>
                                    <img src={recursosActivos.evaluacion ? visto : noVisto} alt="img" />
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                    <div className='retro-likes'>
                        <Container maxWidth="lg" >
                            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
                                <Grid item xs={12} sm={12} md={7} lg={7} className='center wow zoomIn' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                                    <div className='blanco'>
                                        <Grid container direction="row" alignItems="center" spacing={2}>
                                            <Grid item xs={8} sm={8} md={10} lg={10} className='texto-feedback'>
                                                <b>No olvides decirnos si te gustaron los contenidos</b>
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={2} lg={2}>
                                                <div className='contenedor-likes'>
                                                    <img className='btn-likes' src={likeOn} alt="img" />
                                                    <img className='btn-likes' src={dislikeOn} alt="img" />
                                                </div>
                                            </Grid>
                                        </Grid>

                                    </div>
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}