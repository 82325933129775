const texto = [
	{
		autococimiento: {
			bien: 'Conoces acerca de la gestión de tus emociones y el estrés para aprovecharlos en tu liderazgo.',
			mal: 'Aún puedes mejorar en el entendimiento sobre cómo afectan las emociones a tu liderazgo.',
		},
		planeacion: {
			bien: 'Comprendes que priorizar adecuadamente es la manera más eficiente de conseguir resultados.',
			mal: 'Recuerda las estrategias para priorizar y enfocarte en sacar el mejor provecho a tu esfuerzo.',
		},
		bienestar: {
			bien: 'Tienes claro que el bienestar es un elemento indispensable para el buen funcionamiento de tu equipo y tienes las bases para conseguirlo.',
			mal: 'Puedes reforzar los comportamientos que te ayudarán a lograr un estado de bienestar en ti o tu equipo.',
		},
	},
	{
		autococimiento: {
			bien: 'Sabes cómo aprovechar el autoconocimiento para gestionar tus emociones y tomar mejores decisiones.',
			mal: 'Necesitas comprender más sobre el autoconocimiento para aprender a gestionar mejor tus emociones.',
		},
		planeacion: {
			bien: 'Comprendes que la mejor manera de trabajar es planear tu jornada y dosificar tu esfuerzo de manera consciente.',
			mal: 'Aún puedes aplicar aprendizajes de este módulo en tu planeación y gestión del tiempo.',
		},
		bienestar: {
			bien: 'Tienes claro que el bienestar es un elemento indispensable para el buen funcionamiento de tu equipo y tienes las bases para conseguirlo.',
			mal: 'Puedes reforzar los comportamientos que te ayudarán a lograr un estado de bienestar en ti o tu equipo.',
		},
	},
];
export default texto;
