import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Grid, TextField, Button } from '@material-ui/core';
import { updateData, getDate, searchDataByValue, getCollectionsComplete } from '../../../resources/js/functions';

export default function FormLogin(props) {
	const [campos, setCampos] = useState({});
	const [formulario, setFormulario] = useState(true);
	const [error, setError] = useState({ noUser: false, correo: false });
	const navigate = useNavigate();

	const validar = async (e) => {
		e.preventDefault();
		let json = {
			ultimaConexion: getDate(),
		};
		if (Object.keys(campos).length === 0) {
			Swal.fire({
				title: 'Error',
				text: 'Ingresa tu número de empleado',
				icon: 'error',
				confirmButtonText: 'Cerrar',
				confirmButtonColor: '#2D6DB4',
				// cancelButtonColor: "#2D6DB4",
			});
		} else {
			const resultado = await searchDataByValue('users', 'id', campos.numEmpleado);
			const config = await getCollectionsComplete('configuracion');
			if (config.status === 'Inactivo') {
				Swal.fire({
					title: '¡Error!',
					html: 'Sito desactivado por favor contacta al equipo de Spira México',
					icon: 'error',
					confirmButtonText: 'Cerrar',
					confirmButtonColor: '#2D6DB4',
				});
				return null;
			}

			if (Object.values(resultado).length > 0) {
				if (!resultado.activo) {
					Swal.fire({
						title: '¡Error!',
						html: '<p>No estas registrado como participante.</p><p>Verifica que estés ingresando correctamente tu número de empleado.</p><p>Para más informes, envía un correo a <a href="mailto:melissa.valdez@heineken.com">melissa.valdez@heineken.com</a> con el asunto The PEOPLE Leader.</p>',
						icon: 'error',
						confirmButtonText: 'Cerrar',
						confirmButtonColor: '#2D6DB4',
					});
				} else {
					Swal.fire({
						title: `Tu nombre es: `,
						text: `${resultado.nombre}`,
						icon: 'success',
						showCancelButton: true,
						confirmButtonColor: '#2D6DB4',
						confirmButtonText: 'Sí',
						cancelButtonColor: '#d54436',
						cancelButtonText: 'No',
					}).then(async (result) => {
						if (result.isConfirmed) {
							try {
								updateData('users', campos.numEmpleado, json)
									.then(() => {
										localStorage.setItem('0hNZeY/gjEeJihP9bIPGHw==', campos.numEmpleado);
										localStorage.setItem('0hNZeY/gjEeJihP9bIPGHw', resultado.audiencia);
										navigate('/seleccionar-contenido');
									})
									.catch((e) => console.log(e));
							} catch (error) {
								console.log('====================================');
								console.log('error setDataBase: ' + error);
								console.log('====================================');
							}
						}
					});
				}
			} else {
				Swal.fire({
					title: '¡Error!',
					html: '<p>No estas registrado como participante.</p><p>Verifica que estés ingresando correctamente tu número de empleado.</p><p>Para más informes, envía un correo a <a href="mailto:eliana.reyes@heineken.com">eliana.reyes@heineken.com</a> con el asunto The PEOPLE Leader.</p>',
					icon: 'error',
					confirmButtonText: 'Cerrar',
					confirmButtonColor: '#2D6DB4',
				});
			}
			// Swal.fire({
			//   title: "La plataforma estara disponible apartir del 3 de Agosto",
			//   html: "<h2><b>¡Te esperamos!</b></h2>",
			//   icon: "warning",
			//   confirmButtonText: "Cerrar",
			//   confirmButtonColor: "#2D6DB4",
			// });
		}
	};

	return (
		<Grid item xs={12} sm={6} md={7} lg={7}>
			<form onSubmit={validar}>
				<div className='container-form-login'>
					<div className='size-form'>
						<div className='text-form'>Ingresa con tu número de empleado.</div>
						{error.correo && (
							<div className='error'>
								<i className='fas fa-exclamation-triangle' /> {error.correo}
							</div>
						)}
						{formulario && (
							<TextField
								onKeyPress={(event) => {
									if (!/[0-9]/.test(event.key)) {
										event.preventDefault();
									}
								}}
								fullWidth
								size='small'
								label='Número de empleado'
								variant='filled'
								inputProps={{ maxLength: 9 }}
								value={campos.numEmpleado}
								onChange={(e) => setCampos({ ...campos, numEmpleado: e.target.value })}
							/>
						)}
						&nbsp;
						<div className='full space-footer-form'>
							<Button className='btn ' type='submit'>
								Iniciar sesión
							</Button>

							{/* <div className="btna btna" type="submit" onClick={() => navigate('/acerca-de')}>
                Conoce más del programa
              </div> */}
							{/* <span className="register">
                ¿Aún no tienes cuenta? &nbsp;&nbsp;&nbsp;
                <span className="register-text" onClick={() => activeView(2)}>
                  Regístrate
                </span>
              </span> */}
						</div>
						{/* <div className="center full reset-password" onClick={() => activeView(3)}>
              ¿Olvidaste tu contraseña?
            </div> */}
					</div>
				</div>
			</form>
		</Grid>
	);
}
