import React from "react";
import { useParams } from "react-router-dom";
import { Grid, Container } from "@material-ui/core";
import { ejectutivos, jefaturasyCoordinadores } from '../../informacion'
import karla from '../../../../../../resources/images/pilares/gestion/karina-cantu.svg'
import carlos from '../../../../../../resources/images/pilares/gestion/carlos-adrian.svg'
import talina from '../../../../../../resources/images/pilares/gestion/talina-gonzales.svg'
import eduardo from '../../../../../../resources/images/pilares/gestion/eduardo-munoz.svg'

export default function SpeakersConfertencias() {
  const params = useParams();

  return (
    <>
      <Container maxWidth="lg" className="mb30 mt30">
        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
          <Grid item lg={8} md={12} xs={12}>
            <div className="titulos">Acerca del experto</div>
          </Grid>
        </Grid>
      </Container>
      {params.id === 'ejecutivos' && <>
        <Container maxWidth="lg" style={{ marginBottom: '60px' }}>
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
            <Grid item lg={5} md={12} xs={12} >
              <div className="full">
                <div className="name-conferencista full ">
                  <div >
                    <img className="img-conferencista" src={talina} alt="img" />
                  </div>
                  <span className="tendencias bold" style={{ display: "inline-block" }}>
                    <span className="job-conferencista">
                      <span className="name">Talina González</span>
                      <span className="description-conferencista">
                        <ul>
                          <li>People & Organizational Development Director</li>
                        </ul>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item lg={5} md={12} xs={12} >
              <div className="full">
                <div className="name-conferencista full ">
                  <div >
                    <img className="img-conferencista" src={eduardo} alt="img" />
                  </div>
                  <span className="tendencias bold" style={{ display: "inline-block" }}>
                    <span className="job-conferencista">
                      <span className="name"> Eduardo Muñoz</span>
                      <span className="description-conferencista">
                        <ul>
                          <li>Senior Director HR AMERICAS</li>
                        </ul>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container></>}
      {params.id === 'jefaturas-y-coordinaciones' && <>
        <Container maxWidth="lg" style={{ marginBottom: '60px' }}>
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
            <Grid item lg={5} md={12} xs={12} >
              <div className="full">
                <div className="name-conferencista full ">
                  <div >
                    <img className="img-conferencista" src={karla} alt="img" />
                  </div>
                  <span className="tendencias bold" style={{ display: "inline-block" }}>
                    <span className="job-conferencista">
                      <span className="name">Karina Cantú</span>
                      <span className="description-conferencista">
                        <ul>
                          <li>Learning & Development Manager</li>
                        </ul>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item lg={5} md={12} xs={12} >
              <div className="full">
                <div className="name-conferencista full ">
                  <div >
                    <img className="img-conferencista" src={carlos} alt="img" />
                  </div>
                  <span className="tendencias bold" style={{ display: "inline-block" }}>
                    <span className="job-conferencista">
                      <span className="name">Carlos Adrián García</span>
                      <span className="description-conferencista">
                        <ul>
                          <li>Global Functional Learning & Capability Manager</li>
                        </ul>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </>}

    </>
  );
}
