import React, { useState } from 'react';
import { Grid, Container } from "@material-ui/core";
import { isMobileOnly } from 'react-device-detect';
import Modal from '../../../../../../components/Modal';
import cuadro1 from '../../../../../../resources/images/broshure/seccion11/bienestar/cuadro1.svg'
import cuadro1Texto from '../../../../../../resources/images/broshure/seccion11/bienestar/cuadro1Texto.svg'
import cuadro2 from '../../../../../../resources/images/broshure/seccion11/bienestar/cuadro2.svg'
import cuadro2Texto from '../../../../../../resources/images/broshure/seccion11/bienestar/cuadro2Texto.svg'
import imgContenido from '../../../../../../resources/images/broshure/seccion11/bienestar/img-contenido.svg'
import textoContenido from '../../../../../../resources/images/broshure/seccion11/bienestar/texto-contenido.svg'
import btnContenido from '../../../../../../resources/images/broshure/seccion11/bienestar/btn-contenido.svg'
import imgExpertos from '../../../../../../resources/images/broshure/seccion11/bienestar/img-expertos.svg'
import textoExpertos from '../../../../../../resources/images/broshure/seccion11/bienestar/texto-expertos.svg'
import btnExpertos from '../../../../../../resources/images/broshure/seccion11/bienestar/btn-expertos.svg'
// expertos
import popupContenido from '../../../../../../resources/images/broshure/seccion11/bienestar/popup-contenido-bienestar.svg'
import expertoAlejandraBarbas from '../../../../../../resources/images/broshure/seccion11/bienestar/alejandra-barba.svg'
import expertoAlejandraBarbasTitulo from '../../../../../../resources/images/broshure/seccion11/bienestar/titulo-alejandra-barba.svg';
import expertoAlejandraBarbasDescripcion from '../../../../../../resources/images/broshure/seccion11/bienestar/descripcion-alejandra-barba.svg';
import Linkeding from '../../../../../../resources/images/broshure/seccion11/bienestar/linkeding.svg';
import expertoMarcellaPlata from '../../../../../../resources/images/broshure/seccion11/bienestar/marcella-plata.svg'
import expertoMarcellaPlataTitulo from '../../../../../../resources/images/broshure/seccion11/bienestar/titulo-marcella-plata.svg';
import expertoMarcellaPlataDescripcion from '../../../../../../resources/images/broshure/seccion11/bienestar/descripcion-marcella-plata.svg';
import expertoSandraBravo from '../../../../../../resources/images/broshure/seccion11/bienestar/sandra-bravo.svg'
import expertoSandraBravoTitulo from '../../../../../../resources/images/broshure/seccion11/bienestar/titulo-sandra-bravo.svg';
import expertoSandraBravoDescripcion from '../../../../../../resources/images/broshure/seccion11/bienestar/descripcion-sandra-bravo.svg';

// mobile
import img1Mobile from '../../../../../../resources/images/broshure/seccion11/bienestar/mobile/img1-mobile.svg';
import img2Mobile from '../../../../../../resources/images/broshure/seccion11/bienestar/mobile/img2-mobile.svg';
import img3Mobile from '../../../../../../resources/images/broshure/seccion11/bienestar/mobile/img3-mobile.svg';
import img3Popup from '../../../../../../resources/images/broshure/seccion11/bienestar/mobile/img1-popup-bienestar.svg';

export default function Bienestar() {
    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);

    return (
        <div id="bienestar">
            {!isMobileOnly && <Container maxWidth="lg">
                <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={0}>
                    <Grid item xs={12} sm={8} md={8} lg={8} className='center wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        <div className='border-c1' style={{ background: '#F8F7F7' }}>
                            <img src={cuadro1} alt="img" className='full' />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4} className='center wow zoomInRight' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        <div className='center border-c2' style={{ background: '#F8F7F7' }}>
                            <img src={cuadro2} alt="img" className='' style={{ width: '97.8%' }} />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={8} md={8} lg={8} className='center wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        <div className='cuadro-blanco border-c3'>
                            <img src={cuadro1Texto} alt="img" className='full' />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4} className='center wow zoomInRight' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        <div className='cuadro-blanco border-c4'>
                            <img src={cuadro2Texto} alt="img" className='cuadro2Texto-bienestar' />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} className='center border-c5 wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={0} style={{ padding: '30px' }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} className='center'>
                                <img src={imgContenido} alt="img" style={{ marginRight: '20px', marginBottom: '20px' }} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className='center'>
                                <img src={textoContenido} alt="img" /><br /><br />
                                <img className='cursor' src={btnContenido} alt="img" onClick={() => setModal(true)} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} className='border-c6 wow zoomInRight' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={0} style={{ padding: '30px' }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} className='center'>
                                <img src={imgExpertos} alt="img" style={{ marginRight: '20px', marginBottom: '20px' }} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className='center'>
                                <img src={textoExpertos} alt="img" /><br /><br />
                                <img className='cursor' src={btnExpertos} alt="img" onClick={() => setModal2(true)} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>}
            {isMobileOnly && <Container maxWidth="lg">
                <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={0}>
                    <Grid item xs={6} className='center wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        <div className='border-c1' style={{ background: '#F8F7F7' }}>
                            <img src={img1Mobile} alt="img" className='full' />
                        </div>
                    </Grid>
                    <Grid item xs={6} className='center wow zoomInRight' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        <div className='center border-c2' style={{ background: '#F8F7F7' }}>
                            <img src={img2Mobile} alt="img" className='full' />
                        </div>
                    </Grid>
                    <Grid item xs={12} className='center wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        <div className='cuadro-blanco border-c3'>
                            <img src={isMobileOnly ? img3Mobile : cuadro1Texto} alt="img" className='full' />
                        </div>
                    </Grid>
                    <Grid item xs={12} className='center wow zoomInRight' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        <div className='cuadro-blanco border-c4'>
                            <img src={cuadro2Texto} alt="img" className='cuadro2Texto-bienestar' />
                        </div>
                    </Grid>
                    <Grid item xs={12} className='center border-c5 wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={0} style={{ padding: '30px' }}>
                            <Grid item xs={12} className='center'>
                                <img src={imgContenido} alt="img" style={{ marginRight: '20px', marginBottom: '20px' }} />
                            </Grid>
                            <Grid item xs={12} className='center'>
                                <img src={textoContenido} alt="img" className='full' /><br /><br />
                                <img className='cursor' src={btnContenido} alt="img" onClick={() => setModal(true)} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className='border-c6 wow zoomInRight' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={0} style={{ padding: '30px' }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} className='center'>
                                <img src={imgExpertos} alt="img" style={{ marginRight: '20px', marginBottom: '20px' }} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className='center'>
                                <img src={textoExpertos} alt="img" /><br /><br />
                                <img className='cursor' src={btnExpertos} alt="img" onClick={() => setModal2(true)} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>}
            <Modal open={modal} >
                <Container maxWidth="lg">
                    <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={0} style={{ padding: '30px' }}>
                        <div className='full' >
                            <div className='cursor btn-cerrar-popup' onClick={() => setModal(false)}>
                                Cerrar
                            </div>
                        </div>
                        <img src={isMobileOnly ? img3Popup : popupContenido} alt="img" className="contenido-bienestar-popup1" />
                    </Grid>
                </Container>
            </Modal>
            <Modal open={modal2}>
                <div className='full' >
                    <div className='cursor btn-cerrar-popup' onClick={() => setModal2(false)}>
                        Cerrar
                    </div>
                </div>
                <div className='bg-expertos'>
                    <Container maxWidth="lg" >
                        <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={0} style={{ padding: '30px' }}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <div className='titulo-expertos-popup'>
                                    Expertos
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <div className="cuadro-blanco">
                                    <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={3} style={{ padding: '30px' }}>
                                        <Grid item xs={12} sm={6} md={3} lg={3}>
                                            <div className='contenedor-experto'>
                                                <div className='relative'>
                                                    <img src={expertoAlejandraBarbas} alt="alt" />
                                                    <img src={expertoAlejandraBarbasTitulo} alt="alt" className='titulo' />
                                                    <img src={Linkeding} alt="alt" className='linkeding' onClick={() => window.open('https://www.linkedin.com/in/alejandra-barba-7055bb48/', '_blank')} />
                                                </div>
                                                <div className='padding'>
                                                    <img src={expertoAlejandraBarbasDescripcion} alt="alt" className='full' />
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3} lg={3}>
                                            <div className='contenedor-experto'>
                                                <div className='relative'>
                                                    <img src={expertoMarcellaPlata} alt="alt" />
                                                    <img src={expertoMarcellaPlataTitulo} alt="alt" className='titulo' />
                                                    <img src={Linkeding} alt="alt" className='linkeding' onClick={() => window.open('https://www.linkedin.com/in/marcellacoach/', '_blank')} />
                                                </div>
                                                <div className='padding'>
                                                    <img src={expertoMarcellaPlataDescripcion} alt="alt" className='full' />
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3} lg={3}>
                                            <div className='contenedor-experto'>
                                                <div className='relative'>
                                                    <img src={expertoSandraBravo} alt="alt" />
                                                    <img src={expertoSandraBravoTitulo} alt="alt" className='titulo' />
                                                    <img src={Linkeding} alt="alt" className='linkeding' onClick={() => window.open('https://www.linkedin.com/in/sandra-bravo-jim%C3%A9nez-480593/', '_blank')} />
                                                </div>
                                                <div className='padding'>
                                                    <img src={expertoSandraBravoDescripcion} alt="alt" className='full' />
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </Modal>
        </div >
    )
}