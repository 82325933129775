import React, { useState } from 'react';
import { Grid, Container } from "@material-ui/core";
import { isMobileOnly } from 'react-device-detect';
import Carousel from './Carousel';
import Modal from '../../../../../../components/Modal';
import cuadro1 from '../../../../../../resources/images/broshure/seccion11/gestion/cuadro1.svg'
import cuadro2 from '../../../../../../resources/images/broshure/seccion11/gestion/cuadro2.svg'
import cuadro3 from '../../../../../../resources/images/broshure/seccion11/gestion/cuadro3.svg'
import popupContenido from '../../../../../../resources/images/broshure/seccion11/gestion/info.svg'
import popupExpertos from '../../../../../../resources/images/broshure/seccion11/gestion/popup-gestion.svg'
import imgContenido from '../../../../../../resources/images/broshure/seccion11/bienestar/img-contenido.svg'
import textoContenido from '../../../../../../resources/images/broshure/seccion11/bienestar/texto-contenido.svg'
import btnContenido from '../../../../../../resources/images/broshure/seccion11/bienestar/btn-contenido.svg'
import imgExpertos from '../../../../../../resources/images/broshure/seccion11/bienestar/img-expertos.svg'
import textoExpertos from '../../../../../../resources/images/broshure/seccion11/bienestar/texto-expertos.svg'
import btnExpertos from '../../../../../../resources/images/broshure/seccion11/bienestar/btn-expertos.svg'

export default function Inclusion() {
    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);

    return (
        <div id="gestion">
            <Container maxWidth="lg">
                <Grid container direction="row" alignItems="stretch" spacing={0}>
                    <Grid item xs={12} sm={12} md={8} lg={8} className='center wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        <div className='border-c1' style={{ background: '#F8F7F7' }}>
                            <img src={cuadro1} alt="img" className='full' />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} className='padre wow zoomInRight' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        <div className='center hijos border-c2' style={{ background: '#ff' }}>
                            <div className="cuadro-blanco">
                                <img src={cuadro2} alt="img" className='img2TextoGestion' />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} className='padre border-c3 wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        <div className='cuadro-blanco hijos'>
                            {!isMobileOnly && <img src={cuadro3} alt="img" className='full' />}
                            {isMobileOnly && <Carousel />}
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6} className='center border-c5 wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        {/* <div className='cuadro-blanco padre' style={{margin: 'auto', padding: '30px'}}>
                            <div className='hijos'>
                                <img src={imgContenido} alt="img" style={{ marginRight: '20px', marginBottom: '20px' }} />
                            </div>
                            <div className='hijos'>
                                <img src={textoContenido} alt="img" /><br /><br />
                                <img className='cursor' src={btnContenido} alt="img" />
                            </div>
                        </div> */}
                        <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={0} style={{ padding: '30px' }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} className='center'>
                                <img src={imgContenido} alt="img" style={{ marginRight: '20px', marginBottom: '20px' }} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className='center'>
                                <img src={textoContenido} alt="img" /><br /><br />
                                <img className='cursor' src={btnContenido} alt="img" onClick={() => setModal(true)} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} className='border-c6 wow zoomInRight' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={0} style={{ padding: '30px' }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} className='center'>
                                <img src={imgExpertos} alt="img" style={{ marginRight: '20px', marginBottom: '20px' }} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className='center'>
                                <img src={textoExpertos} alt="img" /><br /><br />
                                <img className='cursor' src={btnExpertos} alt="img" onClick={() => setModal2(true)} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Modal open={modal} onClose={() => setModal(false)}>
                <Container maxWidth="lg">
                    <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={0} style={{ padding: '30px' }}>
                        <div className='full' >
                            <div className='cursor btn-cerrar-popup' onClick={() => setModal(false)}>
                                Cerrar
                            </div>
                        </div>
                        <img src={popupContenido} alt="img" className="contenido-popup1" />
                    </Grid>
                </Container>
            </Modal>
            <Modal open={modal2} onClose={() => setModal2(false)}>
                <Container maxWidth="lg">
                    <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={0} style={{ padding: '30px' }}>
                        <div className='full' >
                            <div className='cursor btn-cerrar-popup' onClick={() => setModal2(false)}>
                                Cerrar
                            </div>
                        </div>
                        <img src={popupExpertos} alt="img" className="" />
                    </Grid>
                </Container>
            </Modal>
        </div >
    )
}