import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Container } from "@material-ui/core";
import ValidateUser from "../../../components/ValidateUser";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import SpeakersConferencias from "./SpeakersConferencias";
import { top, } from "../../../resources/js/functions";
import "./styles.scss";

export default function StreamingOnLine() {
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    top();
  }, []);

  const url = params.id === 'ejecutivos' ? 'https://player.vimeo.com/video/864664565?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479' : 'https://player.vimeo.com/video/864864692?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
  return (
    <>
      <ValidateUser>
        <Header />
        <div id="streamingOnLine">
          <Container maxWidth="lg">
            <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={3}>
              <Grid item lg={12} md={12} xs={12}>
                <div className="cursor" onClick={() => navigate(-1)}>{'< Regresar'}</div>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <div className="titulos">{params.id === 'ejecutivos' ? 'Podcast: Viaje del bienestar' : 'Podcast: Bienestar para llevar'}</div>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                {params.id === 'ejecutivos' ?
                  <p>Escucha cómo lograr una sensación de bienestar integral a través de herramientas prácticas que te ayudarán a manejar el estrés y las distracciones.</p> :
                  <p>Escucha consejos para poner en práctica la empatía, generar confianza y tener mayor flexibilidad.</p>
                }
              </Grid>
              <Grid item lg={8} md={12} xs={12}>
                <div className="contenedor-streaming">
                  <iframe title="streaming" src={url} allow="autoplay; fullscreen" frameBorder="0" allowFullScreen className="estilo-streaming"></iframe>
                </div>
              </Grid>
              <SpeakersConferencias />
              <Grid item lg={12} md={12} xs={12} className='center'>
                <div className="cursor" onClick={() => navigate(-1)}>{'< Regresar'}</div>
              </Grid>
            </Grid>
          </Container>
          <Footer />
        </div>
      </ValidateUser>
    </>
  );
}
