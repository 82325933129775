const questionario = {
	pregunta1: {
		pregunta:
			'Pregunta a tus colaboradores sobre la ejecución de sus tareas para asegurarte de que cuentan con los recursos necesarios para realizarlas.',
		opcionA: 'Encuentra un terreno común con otros',
		opcionB: 'Comunícate de forma incluyente',
		opcionC: 'Proporciona igualdad de oportunidades ',
	},
	pregunta2: {
		pregunta: 'Clarifica tus expectativas con detalle, proporciona instrucciones claras y mantente disponible para resolver cualquier duda.',
		opcionA: 'Sé transparente',
		opcionB: 'Hazlo Seguro para los demás',
		opcionC: 'Comunícate de forma incluyente',
	},
	pregunta3: {
		pregunta: 'Permite que tus colaboradores opinen sobre las cargas de trabajo y las tareas de los proyectos.',
		opcionA: 'Hazlo Seguro para los demás',
		opcionB: 'Planea, ejecuta y logra los entregables en equipo',
		opcionC: 'Encuentra un terreno común con otros',
	},
	pregunta4: {
		pregunta: 'Conoce a tus colaboradores directos y conversa para identificar sus fortalezas, necesidades y aspiraciones.',
		opcionA: 'Desarrolla autoconciencia',
		opcionB: 'Encuentra un terreno común con otros',
		opcionC: 'Planea, ejecuta y logra los entregables en equipo',
	},
	pregunta5: {
		pregunta: 'Permite la expresión de opiniones e ideas, incluso cuando son opuestas o diferentes a las tuyas.',
		opcionA: 'Busca múltiples puntos de vista',
		opcionB: 'Desarrolla autoconciencia',
		opcionC: 'Sé transparente',
	},
	pregunta6: {
		pregunta: 'Promueve que en los equipos haya diversidad de ideas, creencias o formación para generar resultados diferentes.',
		opcionA: 'Desarrolla autoconciencia',
		opcionB: 'Encuentra un terreno común con otros',
		opcionC: 'Planea, ejecuta y logra los entregables en equipo',
	},
	pregunta7: {
		pregunta: 'Frena cualquier comunicación violenta desde el momento en que ocurra.',
		opcionA: 'Sé transparente',
		opcionB: 'Proporciona igualdad de oportunidades ',
		opcionC: 'Hazlo Seguro para los demás',
	},
	pregunta8: {
		pregunta: 'Identifica juicios y sesgos personales que puedan estar derivando en microagresiones involuntarias.',
		opcionA: 'Comunícate de forma incluyente',
		opcionB: 'Desarrolla autoconciencia',
		opcionC: 'Sé transparente',
	},
	pregunta9: {
		pregunta: 'Evita hablar con palabras muy especializadas o en otro idioma con personas que pudieran no conocerlas.',
		opcionA: 'Planea, ejecuta y logra los entregables en equipo',
		opcionB: 'Encuentra un terreno común con otros',
		opcionC: 'Comunícate de forma incluyente',
	},
	pregunta10: {
		pregunta: 'Aprovecha cualquier desacuerdo con tus colaboradores para pedir su punto de vista sobre algo que puede no estar funcionando.',
		opcionA: 'Otorga y recibe retroalimentación',
		opcionB: 'Comunícate de forma incluyente',
		opcionC: 'Proporciona igualdad de oportunidades ',
	},
	pregunta11: {
		pregunta: 'Haz introspección sobre lo que sientes cuando te confrontan e identifica si recibir cuestionamientos influye en tus decisiones.',
		opcionA: 'Hazlo Seguro para los demás',
		opcionB: 'Sé transparente',
		opcionC: 'Desarrolla autoconciencia',
	},
	pregunta12: {
		pregunta: 'Si a una persona se le dificulta más una tarea que a otra, acércate y averigua como la puedes ayudar para que no se quede atrás.',
		opcionA: 'Encuentra un terreno común con otros',
		opcionB: 'Comunícate de forma incluyente',
		opcionC: 'Proporciona igualdad de oportunidades ',
	},
};

export default questionario;
