const questionario = {
	pregunta1: {
		pregunta: '1.	¿Qué acciones realiza un líder narrador?',
		opcionA: 'a.	Genera impacto, inspira y vincula las tareas del equipo; en la cadena del propósito de la compañía',
		opcionB: 'b.	Comparte discursos convincentes e inspira a su equipo y a líderes',
		opcionC: 'c.	Habla en público y presenta resultados ',
	},
	pregunta2: {
		pregunta: '2.	¿Qué acciones realiza un líder activador?',
		opcionA: 'a.	Estimula constantemente al equipo, brinda palabras de apoyo',
		opcionB: 'b.	Comparte la pasión de lo que hace, promueve espacios para la innovación y crea puentes de comunicación en su equipo',
		opcionC: 'c.	Escucha a su equipo y se cerciora que cumplan sus indicadores',
	},
	pregunta3: {
		pregunta: '3.	¿Por qué es importante que el líder vincule, personas y resultados para el éxito de la compañía?',
		opcionA: 'a.	No es necesario, el trabajo se tiene que lograr sin importar cómo',
		opcionB: 'b.	Porque es una fortaleza que permite construir resultados, impulsa a las personas de manera balanceada, entre trabajo y bienestar',
		opcionC: 'c.	Porque son iniciativas internas que solo deben conocer los líderes de la organización',
	},
	pregunta4: {
		pregunta: '4.	¿Cómo puede formar un líder equipos comprometidos?',
		opcionA: 'a.	Acatando las tareas asignadas y verificando que se ejecuten correctamente',
		opcionB: 'b.	Involucrando a las personas en procesos de innovación, adaptándose al mercado ',
		opcionC: 'c.	Seleccionando a las personas idóneas para los diferentes tipos de líderes en la compañía',
	},
	pregunta5: {
		pregunta: '5.	¿Por qué es importante que un líder conozca sus procesos y funciones?',
		opcionA: 'a.	Porque puede generar conversaciones que permiten vincular el propósito de su área con los objetivos de la compañía',
		opcionB: 'b.	No es necesario ya que aprende de la experiencia',
		opcionC: 'c.	Porque así construye buenas relaciones con sus compañeros y no interfiere en sus tareas asignadas',
	},
	pregunta6: {
		pregunta: '6.	¿Qué significa liderar con propósito?',
		opcionA: 'a.	Planear la comunicación, comparte los objetivos claros a la audiencia y construye el espacio idóneo para generar compromisos',
		opcionB: 'b.	Se apega a una minuta, es ambiguo en su conversación y prioriza el tiempo ante la calidad de la información',
		opcionC: 'c.	Es concreto en sus oraciones, asume claridad en su discurso y espera resultados',
	},
	pregunta7: {
		pregunta: '7.	¿Cuál es el beneficio de que un líder empodere a su equipo?',
		opcionA: 'a.	Compartir responsabilidades y revisar que el equipo las ejecuta',
		opcionB: 'b.	Delegar tareas y da seguimiento a su cumplimento ',
		opcionC: 'c.	Brindar las herramientas adecuadas para que su equipo supere los resultados y se adapta constantemente a los lineamientos de la organización',
	},
	pregunta8: {
		pregunta: '8.	¿Por qué debe un líder adaptar sus mensajes a diferentes audiencias?',
		opcionA: 'a.	Porque cada audiencia debe estar preparada para lo que va a compartir el líder',
		opcionB: 'b.	Porque debe adaptar su mensaje a la audiencia, incluso el material de proyección, PPT, gráficas, diagramas, etc.',
		opcionC: 'c.	No es relevante, ya que la información es la misma',
	},
	pregunta9: {
		pregunta: '9.	¿Cómo puede un líder adaptarse al constante cambio? ',
		opcionA: 'a.	Siendo ejemplo al mostrar apertura al cambio y a la adaptación constante de nuestros procesos ',
		opcionB: 'b.	Siendo estáticos, mantenido nuestros procesos sin posibilidad de ajustes',
		opcionC: 'c.	Ajustando lo mínimo posible nuestros procesos en largos periodos',
	},
	pregunta10: {
		pregunta: '10.	¿Cómo desarrolla un líder la cultura organizacional? ',
		opcionA: 'a.	Estando pendiente de lo que ocurre en el mercado, se adapta y toma decisiones agiles, empodera y desarrolla a su equipo',
		opcionB: 'b.	Apegado a los procesos y productos consolidados en el mercado, la cultura ya esta definida por el modelo de negocio',
		opcionC: 'c.	Construyendo iniciativas locales que desafían los intereses de la compañía',
	},
};

export default questionario;
