import React from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Img from '../../../../resources/images/pilares/bienestar/sandra-bravo.svg';

export default function SpeakersConfertencias() {
  const params = useParams();

  return (
    <>
      <Grid item lg={8} md={12} xs={12}>
        <div className="titulos">Acerca del experto</div>
      </Grid>
      <Grid item lg={8} md={12} xs={12} >
        <div className="full">
          <div className="name-conferencista full ">
            <div >
              {/* <img className="img-conferencista" style={{ borderRadius: '50%' }} src={info.urlFotoCuadrada} alt="img" /> */}
              <img className="img-conferencista" src={Img} alt="img" />
            </div>
            <span className="tendencias bold" style={{ display: "inline-block" }}>
              <span className="job-conferencista">
                {/* <span className="name">{`${info.nombre}`}</span> <br /> */}
                <span className="name">{params.id === 'ejecutivos' ? 'Sandra Bravo' : 'Sandra Bravo'}</span> 
                {/* <span className="description-conferencista">{parse(info.descripcion)}</span> */}
                <span className="description-conferencista">
                  {
                    params.id === 'ejecutivos' ?
                      <ul>
                        <li>Psicóloga</li>
                        <li>Coach</li>
                        <li>Maestría en Programación Neurolingüística</li>
                        <li>Entrenadora del Modelo Mythoself de habla hispana</li>
                        <li>Instructora de Programas de Educación Continua de la Universidad Iberoamericana y el ITAM así cómo en diversas Organizaciones</li>
                        <li>Psicoterapeuta Gestalt. Especialidad: Tratamiento de Adicciones y Psicoterapia Sistémica Familiar</li>
                        <li>Certificación en PNL</li>
                      </ul> :
                      <ul>
                        <li>Psicóloga</li>
                        <li>Coach</li>
                        <li>Maestría en Programación Neurolingüística</li>
                        <li>Entrenadora del Modelo Mythoself de habla hispana</li>
                        <li>Instructora de Programas de Educación Continua de la Universidad Iberoamericana y el ITAM así cómo en diversas Organizaciones</li>
                        <li>Psicoterapeuta Gestalt. Especialidad: Tratamiento de Adicciones y Psicoterapia Sistémica Familiar</li>
                        <li>Certificación en PNL</li>
                      </ul>
                  }
                </span>
              </span>
            </span>
          </div>
        </div>
      </Grid>
    </>
  );
}
