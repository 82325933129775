import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import { Container, Grid, TextField, Button, Paper, MenuItem, FormControlLabel, Switch } from '@material-ui/core';
import Swal from "sweetalert2";
import Header from '../../../../components/HeaderAdmin';
import { updateData, searchDataByValue } from '../../../../resources/js/functions'
import './styles.scss';

export default function ACtualizarUsurios() {
    const [campos, setCampos] = useState({});
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        getUser();
    }, [])

    const getUser = async () => {
        const data = await searchDataByValue('users', 'id', params.id);
        setCampos(data);
    }

    const actualizar = async e => {
        e.preventDefault();
        let json = {}
        json = {
            ...campos,
        };

        updateData("users", campos.id, json).then(() => {
            Swal.fire({
                title: "¡Actualización exitosa!",
                html: `El usuario se actualizó correctamente`,
                icon: "success",
                confirmButtonText: "Cerrar",
                confirmButtonColor: "#2D6DB4",
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/admin/usuarios/')
                }
            })
        })
    }

    if (Object.keys(campos).length === 0) {
        return null;
    }

    return <>
        <Header>
            <div id='actualizarUsuarios'>
                <form onSubmit={actualizar}>
                    <Container maxWidth="lg">
                        <Paper elevation={3} style={{ padding: '15px' }}>
                            <Grid container direction="row" spacing={2}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <h1>Actualizar usuarios</h1>
                                </Grid>

                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <TextField required autoComplete="true" onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }} fullWidth id="numEmpleado" disabled label="Número de Empleado" variant="outlined" onChange={(e) => setCampos({ ...campos, id: e.target.value })} InputLabelProps={{ shrink: true }} value={campos.id} />
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <TextField required autoComplete="true" onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }} fullWidth id="numEmpleado" label="ID Global" variant="outlined" onChange={(e) => setCampos({ ...campos, idGlobal: e.target.value })} value={campos.idGlobal} InputLabelProps={{ shrink: true }} />
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <TextField required fullWidth id="nombre" label="Nombre" variant="outlined" onChange={(e) => setCampos({ ...campos, nombre: e.target.value })} InputLabelProps={{ shrink: true }} value={campos.nombre} />
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <TextField required fullWidth id="puesto" label="Puesto" variant="outlined" onChange={(e) => setCampos({ ...campos, puesto: e.target.value })} InputLabelProps={{ shrink: true }} value={campos.puesto} />
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <TextField required fullWidth id="nombreLineManager" label="Nombre Completo del Line Manager" variant="outlined" onChange={(e) => setCampos({ ...campos, nombreLineManager: e.target.value })} InputLabelProps={{ shrink: true }} value={campos.nombreLineManager} />
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <TextField required select fullWidth id="audiencia" label="Audiencia" variant="outlined" onChange={(e) => setCampos({ ...campos, audiencia: e.target.value })} InputLabelProps={{ shrink: true }} defaultValue={campos.audiencia} value={campos.audiencia}>
                                        <MenuItem value='Ejecutivos'>Ejecutivos</MenuItem>
                                        <MenuItem value='Jefaturas y Coordinaciones'>Jefaturas y Coordinaciones</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <TextField required fullWidth id="statusLineManager" label="Estatus Line Manager" variant="outlined" onChange={(e) => setCampos({ ...campos, statusLineManager: e.target.value })} InputLabelProps={{ shrink: true }} value={campos.statusLineManager} />
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <TextField required fullWidth type="email" id="correo" label="Correo" variant="outlined" onChange={(e) => setCampos({ ...campos, correo: e.target.value })} InputLabelProps={{ shrink: true }} value={campos.correo} />
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <TextField required fullWidth id="j1" label="J1" variant="outlined" onChange={(e) => setCampos({ ...campos, j1: e.target.value })} InputLabelProps={{ shrink: true }} value={campos.j1} />
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <TextField required fullWidth id="j2" label="J2" variant="outlined" onChange={(e) => setCampos({ ...campos, j2: e.target.value })} InputLabelProps={{ shrink: true }} value={campos.j2} />
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <TextField required fullWidth id="j3" label="J3" variant="outlined" onChange={(e) => setCampos({ ...campos, j3: e.target.value })} InputLabelProps={{ shrink: true }} value={campos.j3} />
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <TextField required fullWidth id="j4" label="J4" variant="outlined" onChange={(e) => setCampos({ ...campos, j4: e.target.value })} InputLabelProps={{ shrink: true }} value={campos.j4} />
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <TextField required fullWidth id="j5" label="J5" variant="outlined" onChange={(e) => setCampos({ ...campos, j5: e.target.value })} InputLabelProps={{ shrink: true }} value={campos.j5} />
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <FormControlLabel
                                        value="top"
                                        control={<Switch onChange={(e) => setCampos({ ...campos, activo: !campos.activo })} defaultChecked={campos.activo} color="primary" />}
                                        label="Activo"
                                        labelPlacement="top"
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <div className="full center">
                                        <Button type='submit' className="btn">Actualizar </Button>
                                    </div>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <div className="full center">
                                        <Button type='button' className="btn cancelar" onClick={() => navigate('/admin/usuarios/')}>Cancelar </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Container>
                </form>
            </div>
        </Header>
    </>
}