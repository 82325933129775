import React from "react";
import { useParams } from "react-router-dom";
import { Grid, Container } from "@material-ui/core";
import veronicaSaracco from '../../../../../../resources/images/daring/pilares/veronica-saracco.svg';

export default function SpeakersConfertencias() {
  const params = useParams();

  return (
    <>
      <Container maxWidth="lg" className="mb30 mt30">
        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
          <Grid item lg={8} md={12} xs={12}>
            <div className="titulos">Acerca del experto</div>
          </Grid>
        </Grid>
      </Container>
      {params.id === 'ejecutivos' && <>
        <Container maxWidth="lg">
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
            <Grid item lg={12} md={12} xs={12} >
              <div className="full">
                <div className="name-conferencista full ">
                  <div >
                    <img className="img-conferencista" src={veronicaSaracco} alt="img" />
                  </div>
                  <span className="tendencias bold" style={{ display: "inline-block" }}>
                    <span className="job-conferencista">
                      <span className="name">Verónica Saracco</span>
                      <span className="description-conferencista">
                        <ul>
                          <li>Veronica Saracco es EVP y DEI Senior Consultant Lead LAC en Mercer.</li>
                          <li>Ejecutiva global de Recursos Humanos con gran experiencia en el desarrollo y coaching de líderes.</li>
                          <li>Amplia experiencia como consultora en una amplia gama de industrias en temas relacionados con eficiencia organizacional y cultural, gestion del talento, gestion del cambio.</li>
                          <li>Es Licenciada en Relaciones Industriales por la Universidad Argentina de la Empresa (UADE).</li>
                        </ul>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </>}
      {params.id === 'jefaturas-y-coordinaciones' && <>
        <Container maxWidth="lg">
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
            <Grid item lg={12} md={12} xs={12} >
              <div className="full">
                <div className="name-conferencista full ">
                  <div >
                    <img className="img-conferencista" src={veronicaSaracco} alt="img" />
                  </div>
                  <span className="tendencias bold" style={{ display: "inline-block" }}>
                    <span className="job-conferencista">
                      <span className="name">Verónica Saracco</span>
                      <span className="description-conferencista">
                        <ul>
                          <li>Veronica Saracco es EVP y DEI Senior Consultant Lead LAC en Mercer.</li>
                          <li>Ejecutiva global de Recursos Humanos con gran experiencia en el desarrollo y coaching de líderes.</li>
                          <li>Amplia experiencia como consultora en una amplia gama de industrias en temas relacionados con eficiencia organizacional y cultural, gestion del talento, gestion del cambio.</li>
                          <li>Es Licenciada en Relaciones Industriales por la Universidad Argentina de la Empresa (UADE).</li>
                        </ul>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </>}

    </>
  );
}
