import React, { useState } from 'react';
import { Container, Grid, Button, Paper, TextField, MenuItem } from '@material-ui/core';
import ScrollToTop from 'react-scroll-to-top';
import { reporte, reporteUsuariosActivosTPL, reporteUsuariosActivosTPLConCalif } from '../../../resources/js/functions';
import ValidateAdmin from '../../../components/ValidateAdmin';
import Header from '../../../components/HeaderAdmin';
import { reporteTodos, reporteActivos, reporteInactivos } from '../Usuarios/reportes';
import { reporteMasterclass, reporteOyenteCultivadorLikes } from '../../../resources/js/reportesDaring';

import './styles.scss';

export default function Home() {
	const [cualReporteDescragar, setCualReporteDescragar] = useState(null)

	const cualReporte = () => {
		if (cualReporteDescragar === 'Activos') {
			reporteActivos()
		}
		if (cualReporteDescragar === 'Desactivados') {
			reporteInactivos()
		}
		if (cualReporteDescragar === 'Todos') {
			reporteTodos()
		}
	}

	return (
		<>
			<ValidateAdmin>
				<Header>
					<div id='homeAdmin'>
						<ScrollToTop smooth color='#fff' style={{ backgroundColor: `#C1212A`, padding: '7px 0' }} />
						<Paper elevation={3} style={{ padding: '15px' }}>
							<Container maxWidth='lg'>
								<Grid container direction='row' alignItems='center' justifyContent='center' spacing={2} style={{ padding: '10px' }}>
									<Grid item lg={10} md={12} sm={12} xs={12}>
										<TextField onChange={e => setCualReporteDescragar(e.target.value)} select label="Selecciona una opción para descargar el registro de participante" fullWidth variant='outlined'>
											<MenuItem value="Activos">Activos</MenuItem>
											<MenuItem value="Desactivados">Desactivados</MenuItem>
											<MenuItem value="Todos">Todos</MenuItem>
										</TextField>
									</Grid>
									<Grid item lg={2} md={12} sm={12} xs={12}>
										<Button className='btn' onClick={cualReporte}>
											Descargar
										</Button>
									</Grid>
								</Grid>
							</Container>
						</Paper>
						<Paper elevation={3} style={{ padding: '15px', marginTop: '40px' }}>
							<Container maxWidth='lg' className='border-reports'>
								<Grid container direction='row' alignItems='center' justifyContent='center' spacing={2}>
									<Grid item lg={10} md={12} sm={12} xs={12}>
										<h2>Playbook integrador e infografía interactiva. Reporte de descargas.</h2>
									</Grid>
									<Grid item lg={2} md={12} sm={12} xs={12}>
										<Button className='btn' onClick={reporte}>
											Descargar
										</Button>
									</Grid>
								</Grid>
							</Container>
							{/* <Container maxWidth='lg' className='border-reports'>
								<Grid container direction='row' alignItems='center' justifyContent='center' spacing={2}>
									<Grid item lg={10} md={12} sm={12} xs={12}>
										<h2>Reporte usuarios activos TPL</h2>
									</Grid>
									<Grid item lg={2} md={12} sm={12} xs={12}>
										<Button className='btn' onClick={reporteUsuariosActivosTPL}>
											Descargar
										</Button>
									</Grid>
								</Grid>
							</Container> */}
							<Container maxWidth='lg' className='border-reports'>
								<Grid container direction='row' alignItems='center' justifyContent='center' spacing={2}>
									<Grid item lg={10} md={12} sm={12} xs={12}>
										<h2>Reporte usuarios activos TPL con reporte de avance</h2>
									</Grid>
									<Grid item lg={2} md={12} sm={12} xs={12}>
										<Button className='btn' onClick={reporteUsuariosActivosTPLConCalif}>
											Descargar
										</Button>
									</Grid>
								</Grid>
							</Container>


							{/* <Container maxWidth='lg' className='border-reports'>
								<Grid container direction='row' alignItems='center' justifyContent='center' spacing={2}>
									<Grid item lg={10} md={12} sm={12} xs={12}>
										<h2>Bienestar. Reporte de Recursos Digitales</h2>
									</Grid>
									<Grid item lg={2} md={12} sm={12} xs={12}>
										<Button className='btn' onClick={reporteRecursosBienestar}>
											Descargar
										</Button>
									</Grid>
								</Grid>
							</Container> */}
							{/* <Container maxWidth='lg' className='border-reports'>
								<Grid container direction='row' alignItems='center' justifyContent='center' spacing={2}>
									<Grid item lg={10} md={12} sm={12} xs={12}>
										<h2>Bienestar. Reporte de Feedback</h2>
									</Grid>
									<Grid item lg={2} md={12} sm={12} xs={12}>
										<Button className='btn' onClick={reporteRecursosBienestarFeedback}>
											Descargar
										</Button>
									</Grid>
								</Grid>
							</Container> */}
						</Paper>
						{/* <Paper elevation={3} style={{ padding: '15px', marginTop: '40px' }}>
							<Container maxWidth='lg' className='border-reports'>
								<Grid container direction='row' alignItems='center' justifyContent='center' spacing={2}>
									<Grid item lg={10} md={12} sm={12} xs={12}>
										<h2>Reporte de satisfacción Master Class Daring</h2>
									</Grid>
									<Grid item lg={2} md={12} sm={12} xs={12}>
										<Button className='btn' onClick={reporteMasterclass}>
											Descargar
										</Button>
									</Grid>
								</Grid>
							</Container>
							<Container maxWidth='lg' className='border-reports'>
								<Grid container direction='row' alignItems='center' justifyContent='center' spacing={2}>
									<Grid item lg={10} md={12} sm={12} xs={12}>
										<h2>Reporte de satisfacción pilar Oyente/Cultivador</h2>
									</Grid>
									<Grid item lg={2} md={12} sm={12} xs={12}>
										<Button className='btn' onClick={reporteOyenteCultivadorLikes}>
											Descargar
										</Button>
									</Grid>
								</Grid>
							</Container>
						</Paper> */}
					</div>
				</Header>
			</ValidateAdmin>
		</>
	);
}
