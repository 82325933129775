// expertos
import talina from '../../../resources/images/pilares/gestion/talina-gonzales.svg'
import eduardo from '../../../resources/images/pilares/gestion/eduardo-munoz.svg'

// ejectivos
import PortadaMasterClass from '../../../resources/images/pilares/gestion/ejecutivos/portada-master-class.svg';
import PortadaVideoClass from '../../../resources/images/pilares/gestion/ejecutivos/portada-video-tutorial.svg';
import podcast from '../../../resources/images/pilares/gestion/ejecutivos/podcast.svg';
import playbook from '../../../resources/images/pilares/gestion/ejecutivos/playbook.svg';
import evaluacion from '../../../resources/images/pilares/gestion/ejecutivos/evaluacion.svg';

// jefaturas y coordinaciones
import PortadaMasterClassJefatura from '../../../resources/images/pilares/gestion/jefaturas/portada-master-class.svg';
import PortadaVideoClassJefatura from '../../../resources/images/pilares/gestion/jefaturas/portada-video-tutorial.svg';
import podcastJefatura from '../../../resources/images/pilares/gestion/jefaturas/podcast.svg';
import playbookJefatura from '../../../resources/images/pilares/gestion/jefaturas/playbook.svg';
import evaluacionJefatura from '../../../resources/images/pilares/gestion/jefaturas/evaluacion.svg';

const ejectutivos = {
	videoIntoduccion: 'https://player.vimeo.com/video/821121757?h=f00061b3c1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
	masterClass: {
		portada: PortadaMasterClass,
		url: '/master-class/ejecutivos/gestion',
		titulo: 'Gestión de Talento',
		descripcion: 'Conoce de primera mano cuál es el objetivo y fundamentos de nuestra estrategia para gestionar el talento, así como algunos consejos para implementarla de manera exitosa.',
		urlVideoMasterClass: 'https://player.vimeo.com/video/821942972?h=5a60db4780&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
		imgExperto: talina,
		experto: 'Talina González',
		descripcionExperto: (
			<ul>
				<li>People & Organizational Development Director</li>
			</ul>
		),
		activo: true,
		modal: false,
	},
	tutorial: {
		portada: PortadaVideoClass,
		url: '/tutorial/ejecutivos/gestion',
		titulo: 'Conversaciones de carrera efectivas',
		descripcion: 'Potencia tus habilidades de liderazgo y lleva a tu equipo al siguiente nivel aprendiendo los mejores consejos y tips para lograr conversaciones de carrera impactantes y efectivas.',
		urlVideoTutorial: 'https://player.vimeo.com/video/821119104?h=94cfdeace2&badge=0&autopause=0&player_id=0&app_id=58479',
		imgExperto: talina,
		experto: '',
		descripcionExperto: '',
		activo: false,
	},
	podcast: {
		portada: podcast,
		url: '/podcast/ejecutivos/gestion',
		titulo: 'Nuevo modelo de talento',
		descripcion: 'Escucha y aprende más sobre nuestro nuevo módelo para potenciar el talento en HEINEKEN y cuáles son nuestras expectativas para el futuro.',
		urlPodcast: 'https://player.vimeo.com/video/821118269?h=fd446ab798&badge=0&autopause=0&player_id=0&app_id=58479',
		imgExperto: talina,
		experto: 'Román Rodríguez ',
		descripcionExperto: '',
		activo: true,
	},
	playbook: {
		portada: playbook,
		url: '',
		titulo: 'Guía del líder inclusivo',
		descripcion: '',
		urlPlaybook: 'https://firebasestorage.googleapis.com/v0/b/the-people-leader-connect.appspot.com/o/descargables%2FDG_PeopleLe_Playbook_Gestio%CC%81nTalentoVF_290423.pdf?alt=media&token=d6efdd1d-c90d-4d48-aeff-c57b7f88b354',
		imgExperto: '',
		experto: '',
		descripcionExperto: '',
		activo: true,
	},
	evaluacion: {
		portada: evaluacion,
		url: '/evaluacion/ejecutivos/gestion',
		titulo: 'Evaluación',
		descripcion: '',
		urlVideoMasterClass: '',
		imgExperto: '',
		experto: '',
		descripcionExperto: '',
	},
};

const jefaturasyCoordinadores = {
	videoIntoduccion: 'https://player.vimeo.com/video/821121757?h=f00061b3c1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
	masterClass: {
		portada: PortadaMasterClassJefatura,
		url: '/master-class/jefaturas-y-coordinaciones/gestion',
		titulo: 'Titulo',
		descripcion:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
		urlVideoMasterClass: 'https://player.vimeo.com/video/821942972?h=5a60db4780&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
		imgExperto: talina,
		experto: 'Shirley Saenz',
		descripcionExperto: (
			<>
				<ul>
					<li>CEO</li>
					<li>Experta en Interculturalidad, Diversidad, Equidad e Inclusión</li>
					<li>Conferencista internacional</li>
				</ul>
			</>
		),
		activo: true,
		modal: false,
	},
	tutorial: {
		portada: PortadaVideoClassJefatura,
		url: '/tutorial/jefaturas-y-coordinaciones/gestion',
		titulo: 'Conversaciones de carrera efectivas',
		descripcion: 'Potencia tus habilidades de liderazgo y lleva a tu equipo al siguiente nivel aprendiendo los mejores consejos y tips para lograr conversaciones de carrera impactantes y efectivas.',
		urlVideoTutorial: 'https://player.vimeo.com/video/821119104?h=94cfdeace2&badge=0&autopause=0&player_id=0&app_id=58479',
		imgExperto: '',
		experto: '',
		descripcionExperto: '',
		activo: true,
	},
	podcast: {
		portada: podcastJefatura,
		url: '/podcast/jefaturas-y-coordinaciones/gestion',
		titulo: 'Nuevo modelo de talento y Talent Beliefs',
		descripcion: 'Escucha sobre nuestras creencias sobre el talento y aprende más sobre el nuevo módelo para potenciarlo  en HEINEKEN.',
		urlPodcast: 'https://player.vimeo.com/video/821121782?h=cc95d7be79&badge=0&autopause=0&player_id=0&app_id=58479',
		imgExperto: '',
		experto: 'Líderes HEINEKEN',
		descripcionExperto: '',
		activo: true,
	},
	playbook: {
		portada: playbookJefatura,
		url: '',
		titulo: 'Guía del líder inclusivo',
		descripcion: '',
		urlPlaybook: 'https://firebasestorage.googleapis.com/v0/b/the-people-leader-connect.appspot.com/o/descargables%2FDG_PeopleLe_Playbook_Gestio%CC%81nTalentoVF_290423.pdf?alt=media&token=d6efdd1d-c90d-4d48-aeff-c57b7f88b354',
		imgExperto: '',
		experto: '',
		descripcionExperto: '',
		activo: true,
	},
	evaluacion: {
		portada: evaluacionJefatura,
		url: '/evaluacion/jefaturas-y-coordinaciones/gestion',
		titulo: 'Evaluación',
		descripcion: '',
		urlVideoMasterClass: '',
		imgExperto: '',
		experto: '',
		descripcionExperto: '',
	},
};

export { ejectutivos, jefaturasyCoordinadores };
