import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom'
import { Grid, Container, Button } from "@material-ui/core";
import { top } from "../../../../../../resources/js/functions";
import texto from "./textos";
import img from '../../../../../../resources/images/certificate/img.svg';
import pleca from '../../../../../../resources/images/pilares/gestion/certificate/pleca.svg';
import inclucionMal from '../../../../../../resources/images/pilares/gestion/certificate/inclucion-mal.svg';
import inclucionBien from '../../../../../../resources/images/pilares/gestion/certificate/inclucion-bien.svg';
import equidadMal from '../../../../../../resources/images/pilares/gestion/certificate/equidad-mal.svg';
import equidadBien from '../../../../../../resources/images/pilares/gestion/certificate/equidad-bien.svg';
import transparenciaMal from '../../../../../../resources/images/pilares/gestion/certificate/transparencia-mal.svg';
import transparenciaBien from '../../../../../../resources/images/pilares/gestion/certificate/transparencia-bien.svg';

export default function Reprobado(props) {
    const { perfil, seccion1, seccion2, seccion3, calif } = props;
    const [suma1, setSuma1] = useState(0)
    const [suma2, setSuma2] = useState(0)
    const [suma3, setSuma3] = useState(0)
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const sumaTotal1 = seccion1[0] + seccion1[1] + seccion1[2] + seccion1[3];
        const sumaTotal2 = seccion2[0] + seccion2[1] + seccion2[2] + seccion2[3];
        const sumaTotal3 = seccion3[0] + seccion3[1] + seccion3[2] + seccion3[3];
        setSuma1(sumaTotal1)
        setSuma2(sumaTotal2)
        setSuma3(sumaTotal3)
        top();
    }, [])

    return (
        <>
            <div id="reprobado">
                <Container maxWidth="lg">
                    <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <img src={img} alt="img" className="img-top" />
                        </Grid>
                        <Grid item lg={3} md={3} sm={12} xs={12}>
                            <div className="contenedor-calif">
                                Obtuviste<br />
                                {calif}<br />
                                aciertos de 12
                            </div>
                        </Grid>
                    </Grid>
                </Container>
                <div className="retro mt30">
                    <Container maxWidth="lg">
                        <Grid container direction="row">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <img src={pleca} alt="img" />
                            </Grid>
                        </Grid>
                    </Container>
                    {params.id === 'ejecutivos' && <>
                        <Container maxWidth="lg">
                            <Grid item lg={12} md={10} sm={12} xs={12} justifyContent='center' alignItems="center" >
                                <div className="contenedor-retro">
                                    <Grid container direction="row" spacing={3} alignItems='center' justifyContent="center" className='padre'>
                                        <Grid item lg={2} md={2} sm={12} xs={12} className="hijos center">
                                            <img src={suma1 > 2 ? inclucionBien : inclucionMal} alt="img" />
                                        </Grid>
                                        <Grid item lg={8} md={8} sm={12} xs={12} className="padre">
                                            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2} >
                                                <Grid item lg={4} md={4} sm={12} xs={12} className="hijos padre" >
                                                    <div className="full padre">
                                                        <div className='titulo-retro morado hijos'>
                                                            Creencias sobre
                                                            el talento
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item lg={8} md={8} sm={12} xs={12} className="hijos">
                                                    <p>{suma1 > 2 ? texto[0].inclusion.bien : texto[0].inclusion.mal}</p>
                                                </Grid>
                                            </Grid>
                                            <div className="border" />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" spacing={3} alignItems='center' justifyContent="center" className='padre'>
                                        <Grid item lg={2} md={2} sm={12} xs={12} className="hijos center">
                                            <img src={suma2 > 2 ? equidadBien : equidadMal} alt="img" />
                                        </Grid>
                                        <Grid item lg={8} md={8} sm={12} xs={12} className="hijos">
                                            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2} >
                                                <Grid item lg={4} md={4} sm={12} xs={12} className="hijos padre">
                                                    <div className="full padre">
                                                        <div className='titulo-retro azul hijos'>
                                                            Modelo de Potencial
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item lg={8} md={8} sm={12} xs={12} className="hijos">
                                                    <p>{suma2 > 2 ? texto[0].equidad.bien : texto[0].equidad.mal}</p>
                                                </Grid>
                                            </Grid>
                                            <div className="border" />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" spacing={3} alignItems='center' justifyContent="center" className='padre'>
                                        <Grid item lg={2} md={2} sm={12} xs={12} className="hijos center">
                                            <img src={suma3 > 2 ? transparenciaBien : transparenciaMal} alt="img" />
                                        </Grid>
                                        <Grid item lg={8} md={8} sm={12} xs={12} className="hijos">
                                            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
                                                <Grid item lg={4} md={4} sm={12} xs={12} className="hijos padre">
                                                    <div className="full padre">
                                                        <div className='titulo-retro verde hijos'>
                                                            Conversaciones
                                                            y plan de carrera
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item lg={8} md={8} sm={12} xs={12} className="hijos">
                                                    <p>{suma3 > 2 ? texto[0].transparencia.bien : texto[0].transparencia.mal}</p>
                                                </Grid>
                                            </Grid>
                                            <div className="border" />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Container>
                    </>}
                    {params.id === 'jefaturas-y-coordinaciones' && <>
                        <Container maxWidth="lg">
                            <Grid item lg={12} md={10} sm={12} xs={12} justifyContent='center' alignItems="center" >
                                <div className="contenedor-retro">
                                    <Grid container direction="row" spacing={3} alignItems='center' justifyContent="center" className='padre'>
                                        <Grid item lg={2} md={2} sm={12} xs={12} className="hijos center">
                                            <img src={suma1 > 2 ? inclucionBien : inclucionMal} alt="img" />
                                        </Grid>
                                        <Grid item lg={8} md={8} sm={12} xs={12} className="padre">
                                            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2} >
                                                <Grid item lg={4} md={4} sm={12} xs={12} className="hijos padre" >
                                                    <div className="full padre">
                                                        <div className='titulo-retro morado hijos'>
                                                            Creencias sobre
                                                            el talento
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item lg={8} md={8} sm={12} xs={12} className="hijos">
                                                    <p>{suma1 > 2 ? texto[1].inclusion.bien : texto[1].inclusion.mal}</p>
                                                </Grid>
                                            </Grid>
                                            <div className="border" />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" spacing={3} alignItems='center' justifyContent="center" className='padre'>
                                        <Grid item lg={2} md={2} sm={12} xs={12} className="hijos center">
                                            <img src={suma2 > 2 ? equidadBien : equidadMal} alt="img" />
                                        </Grid>
                                        <Grid item lg={8} md={8} sm={12} xs={12} className="hijos">
                                            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2} >
                                                <Grid item lg={4} md={4} sm={12} xs={12} className="hijos padre">
                                                    <div className="full padre">
                                                        <div className='titulo-retro azul hijos'>
                                                            Modelo de Potencial
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item lg={8} md={8} sm={12} xs={12} className="hijos">
                                                    <p>{suma2 > 2 ? texto[1].equidad.bien : texto[1].equidad.mal}</p>
                                                </Grid>
                                            </Grid>
                                            <div className="border" />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" spacing={3} alignItems='center' justifyContent="center" className='padre'>
                                        <Grid item lg={2} md={2} sm={12} xs={12} className="hijos center">
                                            <img src={suma3 > 2 ? transparenciaBien : transparenciaMal} alt="img" />
                                        </Grid>
                                        <Grid item lg={8} md={8} sm={12} xs={12} className="hijos">
                                            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
                                                <Grid item lg={4} md={4} sm={12} xs={12} className="hijos padre">
                                                    <div className="full padre">
                                                        <div className='titulo-retro verde hijos'>
                                                            Conversaciones
                                                            y plan de carrera
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item lg={8} md={8} sm={12} xs={12} className="hijos">
                                                    <p>{suma3 > 2 ? texto[1].transparencia.bien : texto[1].transparencia.mal}</p>
                                                </Grid>
                                            </Grid>
                                            <div className="border" />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Container>
                    </>}
                </div>
                <Container maxWidth="lg" className="mt30">
                    <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Grid container direction="row" alignItems="center" spacing={3}>
                                <Grid item lg={9} md={6} sm={12} xs={12}>
                                    <p>Puedes intentarlo nuevamente, aunque te recomendamos volver a revisar todos los recursos digitales para reforzar la información.</p>
                                    <p>Recuerda que aprobar la evaluación es un requisito para obtener tu constancia.</p>
                                </Grid>
                                <Grid item lg={3} md={6} sm={12} xs={12} className='mt30'>
                                    <Button className="btn btn-reprobado" onClick={() => navigate('/home')} style={{ width: '100%' }}>Regresar a los recursos</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    );
}

