import React from 'react';
import { Grid, Container } from "@material-ui/core";
import { isMobileOnly } from 'react-device-detect';
import { getDate, updateData } from '../../../../../resources/js/functions'
import Img1 from "../../../../../resources/images/broshure/seccion2/img1.svg";
import img2 from '../../../../../resources/images/broshure/seccion17/img2.svg';

export default function Seccion2() {

    const acctualizarBroshure = async () => {
        updateData('users', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { playBookHome: true, fechaPlaybookHome: getDate() })
        window.open('https://drive.google.com/file/d/1ys4eN-s5I1pz7SnBzZg70Ui6EwEtBHMz/view', '_blank');
    }

    return <section id="seccion2">
        <Container maxWidth="lg" >
            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={6} className='padre wow zoomIn' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                    <div className="center hijos">
                        Descarga el Playbook del Programa
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} className='center wow zoomInRight' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                    <div className="center hijos">
                        <img src={img2} alt="img" className='cursor' style={{ width: `${isMobileOnly ? '200px' : 'auto'}` }} onClick={() => acctualizarBroshure()} />
                    </div>
                </Grid>
            </Grid>
        </Container>
    </section>
}