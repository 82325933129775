import React, { useEffect, useState } from "react";
import { top } from '../../../resources/js/functions';
import Header from "../../../components/Header";
import ValidateUser from "../../../components/ValidateUser";
import Footer from "../../../components/Footer";
import RolUno from './RolUno';
import "./styles.scss";

export default function Home() {
    const [vista, setVista] = useState(1);

    useEffect(() => {
        top();
        localStorage.removeItem('pilar');
    }, [])

    return (
        <>
            <Header />
            <div id="broshure">
                {vista === 1 && <RolUno />}
            </div>
            <Footer />
        </>
    );
}