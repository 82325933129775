// expertos
import talina from '../../../../resources/images/pilares/gestion/talina-gonzales.svg';
import eduardo from '../../../../resources/images/pilares/gestion/eduardo-munoz.svg';

// ejectivos
import PortadaMasterClass from '../../../../resources/images/pilares/gestion/ejecutivos/portada-master-class.svg';
import PortadaVideoClass from '../../../../resources/images/pilares/gestion/ejecutivos/portada-video-tutorial.svg';
import podcast from '../../../../resources/images/pilares/gestion/ejecutivos/podcast.svg';
import playbook from '../../../../resources/images/pilares/gestion/ejecutivos/playbook.svg';
import evaluacion from '../../../../resources/images/pilares/gestion/ejecutivos/evaluacion.svg';

// jefaturas y coordinaciones
import PortadaMasterClassJefatura from '../../../../resources/images/pilares/gestion/jefaturas/portada-master-class.svg';
import PortadaVideoClassJefatura from '../../../../resources/images/pilares/gestion/jefaturas/portada-video-tutorial.svg';
import podcastJefatura from '../../../../resources/images/pilares/gestion/jefaturas/podcast.svg';
import playbookJefatura from '../../../../resources/images/pilares/gestion/jefaturas/playbook.svg';
import evaluacionJefatura from '../../../../resources/images/pilares/gestion/jefaturas/evaluacion.svg';

const ejectutivos = {
	videoIntoduccion: 'https://player.vimeo.com/video/864855161?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
	masterClass: {
		portada: PortadaMasterClass,
		url: '/master-class/ejecutivos/gestion',
		titulo: 'Gestión de Talento',
		descripcion: 'Conoce de primera mano cuál es el objetivo y fundamentos de nuestra estrategia para gestionar el talento, así como algunos consejos para implementarla de manera exitosa.',
		urlVideoMasterClass: 'https://player.vimeo.com/video/866444689?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
		imgExperto: talina,
		experto: 'Talina González',
		descripcionExperto: (
			<ul>
				<li>People & Organizational Development Director</li>
			</ul>
		),
		activo: true,
		modal: false,
	},
	tutorial: {
		portada: PortadaVideoClass,
		url: '/tutorial/ejecutivos/gestion',
		titulo: 'Conversaciones de carrera efectivas',
		descripcion: 'Potencia tus habilidades de liderazgo y lleva a tu equipo al siguiente nivel aprendiendo los mejores consejos y tips para lograr conversaciones de carrera impactantes y efectivas.',
		urlVideoTutorial: 'https://player.vimeo.com/video/868855809?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
		imgExperto: talina,
		experto: 'Carlos Adrián García',
		descripcionExperto: '',
		activo: false,
	},
	podcast: {
		portada: podcast,
		url: '/podcast/ejecutivos/gestion',
		titulo: 'Nuevo modelo de talento y Talent Beliefs',
		descripcion: 'Escucha y aprende más sobre nuestro nuevo módelo para potenciar el talento en HEINEKEN y cuáles son nuestras expectativas para el futuro.',
		urlPodcast: 'https://player.vimeo.com/video/868859739?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
		imgExperto: talina,
		experto: 'Líderes HEINEKEN',
		descripcionExperto: '',
		activo: true,
	},
	playbook: {
		portada: playbook,
		url: '',
		titulo: 'Manual para Líderes',
		descripcion: '',
		// urlPlaybook: 'https://firebasestorage.googleapis.com/v0/b/the-people-leader-connect.appspot.com/o/descargables%2FDG_PeopleLe_Playbook_Gestio%CC%81nTalentoVF_290423.pdf?alt=media&token=d6efdd1d-c90d-4d48-aeff-c57b7f88b354',
		urlPlaybook: 'https://firebasestorage.googleapis.com/v0/b/the-people-leader-connect.appspot.com/o/descargables%2FMYR-Manual%20Li%CC%81deres.pdf?alt=media&token=9177b358-abaf-476d-b409-f2ac8efbb54f',
		imgExperto: '',
		experto: '',
		descripcionExperto: '',
		activo: true,
	},
	evaluacion: {
		portada: evaluacion,
		url: '/evaluacion/ejecutivos/gestion',
		titulo: 'Evaluación',
		descripcion: '',
		urlVideoMasterClass: '',
		imgExperto: '',
		experto: '',
		descripcionExperto: '',
	},
};

const jefaturasyCoordinadores = {
	videoIntoduccion: 'https://player.vimeo.com/video/864855161?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
	masterClass: {
		portada: PortadaMasterClass,
		url: '/master-class/jefaturas-y-coordinaciones/gestion',
		titulo: 'Gestión de Talento',
		descripcion: 'Conoce de primera mano cuál es el objetivo y fundamentos de nuestra estrategia para gestionar el talento, así como algunos consejos para implementarla de manera exitosa.',
		urlVideoMasterClass: 'https://player.vimeo.com/video/866441269?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
		imgExperto: talina,
		experto: 'Talina González',
		descripcionExperto: (
			<ul>
				<li>People & Organizational Development Director</li>
			</ul>
		),
		activo: true,
		modal: false,
	},
	tutorial: {
		portada: PortadaVideoClassJefatura,
		url: '/tutorial/jefaturas-y-coordinaciones/gestion',
		titulo: 'Conversaciones de carrera efectivas',
		descripcion: 'Potencia tus habilidades de liderazgo y lleva a tu equipo al siguiente nivel aprendiendo los mejores consejos y tips para lograr conversaciones de carrera impactantes y efectivas.',
		urlVideoTutorial: 'https://player.vimeo.com/video/868855809?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
		imgExperto: '',
		experto: 'Carlos Adrián García',
		descripcionExperto: '',
		activo: true,
	},
	podcast: {
		portada: podcastJefatura,
		url: '/podcast/jefaturas-y-coordinaciones/gestion',
		titulo: 'Nuevo modelo de talento y Talent Beliefs',
		descripcion: 'Escucha sobre nuestras creencias sobre el talento y aprende más sobre el nuevo módelo para potenciarlo  en HEINEKEN.',
		urlPodcast: 'https://player.vimeo.com/video/868853324?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
		imgExperto: '',
		experto: 'Líderes HEINEKEN',
		descripcionExperto: '',
		activo: true,
	},
	playbook: {
		portada: playbookJefatura,
		url: '',
		titulo: 'Manual para Líderes',
		descripcion: '',
		// urlPlaybook: 'https://firebasestorage.googleapis.com/v0/b/the-people-leader-connect.appspot.com/o/descargables%2FDG_PeopleLe_Playbook_Gestio%CC%81nTalentoVF_290423.pdf?alt=media&token=d6efdd1d-c90d-4d48-aeff-c57b7f88b354',
		urlPlaybook: 'https://firebasestorage.googleapis.com/v0/b/the-people-leader-connect.appspot.com/o/descargables%2FMYR-Manual%20Li%CC%81deres.pdf?alt=media&token=9177b358-abaf-476d-b409-f2ac8efbb54f',
		imgExperto: '',
		experto: '',
		descripcionExperto: '',
		activo: true,
	},
	evaluacion: {
		portada: evaluacionJefatura,
		url: '/evaluacion/jefaturas-y-coordinaciones/gestion',
		titulo: 'Evaluación',
		descripcion: '',
		urlVideoMasterClass: '',
		imgExperto: '',
		experto: '',
		descripcionExperto: '',
	},
};

export { ejectutivos, jefaturasyCoordinadores };
