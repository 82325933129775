import React from 'react';
import { Grid, Container } from "@material-ui/core";
import { isMobileOnly } from 'react-device-detect';
import img1 from '../../../../../resources/images/broshure/seccion12/img1.svg';
import img2 from '../../../../../resources/images/broshure/seccion12/img2.svg';
import img3 from '../../../../../resources/images/broshure/seccion12/img3.svg';
import img4 from '../../../../../resources/images/broshure/seccion12/img4.svg';
import img1Mobile from '../../../../../resources/images/broshure/seccion12/img1-mobile.svg';
import img2Mobile from '../../../../../resources/images/broshure/seccion12/img2-mobile.svg';


export default function Seccion12() {

    return <section id="seccion12">
        <Container maxWidth="lg" >
            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={5} md={6} lg={6} className='center wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                    <div className="center">
                        <img src={img1} alt="img" className='mb30 size' />
                    </div>
                </Grid>
                <Grid item xs={12} sm={7} md={6} lg={6} className='center wow zoomInRight' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                    <div className="center">
                        <img src={img2} alt="img" className='mb30 size' />
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className='center wow zoomInUp' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                    <div className="center">
                        <img src={`${isMobileOnly ? img1Mobile : img3}`} alt="img" className='mb30 size' />
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className='center wow zoomInUp' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                    <div className="center">
                        <img src={`${isMobileOnly ? img2Mobile : img4}`} alt="img" className='size' />
                    </div>
                </Grid>
            </Grid>
        </Container>
    </section>
}