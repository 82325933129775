// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage, ref, listAll } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: 'AIzaSyBENEKCN-DJM6asFRggHPoJV8jIg7jEUr8',
	authDomain: 'the-people-leader-connect.firebaseapp.com',
	databaseURL: 'https://the-people-leader-connect-default-rtdb.firebaseio.com',
	projectId: 'the-people-leader-connect',
	storageBucket: 'the-people-leader-connect.appspot.com',
	messagingSenderId: '410954805070',
	appId: '1:410954805070:web:1a2312ea3da02a8d89b81f',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app)
export const storage = getStorage(app);
