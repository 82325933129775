import React, { useState, useEffect, createRef } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Container, Button, Hidden } from "@material-ui/core";
import Pdf from "react-to-pdf";
import { useScreenshot, createFileName } from 'use-react-screenshot';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { searchDataByValue, top } from "../../../../resources/js/functions";
import Certificado from "../../../../resources/images/certificate/certificado2.svg";
import "../styles.scss";

export default function Certificate() {
  const [user, setUser] = useState({});
  const [left, setLeft] = useState(30);
  const ref = React.createRef();
  const reference = createRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    getUser();
    top();
  }, []);

  const getUser = async () => {
    const usuario = await searchDataByValue("users", "id", localStorage.getItem("0hNZeY/gjEeJihP9bIPGHw=="));
    if (usuario.nombre.length > 30) {
      setLeft(26);
    }
    if (usuario.nombre.length > 9 && usuario.nombre.length < 18) {
      setLeft(40);
    }
    // console.log(usuario);
    setUser(usuario);
  };

  // const options = {
  //   orientation: "landscape",
  //   unit: "in",
  //   format: [4, 1],
  // };

  // const printDocument = () => {
  //   var w = document.getElementById("imgCertificado").offsetWidth;
  //   var h = document.getElementById("imgCertificado").offsetHeight;
  //   // html2canvas(document.getElementById("imgCertificado") {
  //   //   dpi: 300, // Set to 300 DPI
  //   //   scale: 3, // Adjusts your resolution
  //   //   onrendered: function(canvas) {
  //   //     var img = canvas.toDataURL("image/jpeg", 1);
  //   //     var doc = new jsPDF('L', 'px', [w, h]);
  //   //     doc.addImage(img, 'JPEG', 0, 0, w, h);
  //   //     doc.save('sample-file.pdf');
  //   //   }
  //   // });
  //   html2canvas(ref.current).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF("L", "px", [w, h]);
  //     // const imgProps = pdf.getImageProperties(imgData);
  //     // const pdfWidth = pdf.internal.pageSize.getWidth();
  //     // const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  //     pdf.addImage(imgData, "png", 0, 0, w, h);
  //     pdf.save("certificado.pdf");
  //   });
  // };

  const [image, takeScreenshot] = useScreenshot({
    type: "image/png",
    quality: 1.0
  });

  const download = (image, { name = "certificado", extension = "png" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const getImage = async () => {
    let a = reference.current;
    setTimeout(async () => {
      await takeScreenshot(a).then(download);
      // setShow(false)
    }, 1000);
  }

  return (
    <div id="section-certificate">
      <Container maxWidth="lg">
        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
          
          <Grid item lg={11} md={11} sm={12} xs={12}>
            <p className="text">
              Descarga tu constancia y consérvala. Para obtenerla en la mejor calidad posible, te recomendamos hacerlo desde un equipo de escritorio.
              Si no tienes cómo hacerlo ahora mismo, no te preocupes, siempre puedes volver a ingresar para descargar la constancia dando clic a la sección Evaluación.
            </p>
          </Grid>
          <Grid item lg={11} md={11} sm={12} xs={12} style={{ background: "#F8F7F7" }} ref={reference}>
            <div className="center full">
              <div id="imgCertificado" className="certificate">
                <img src={Certificado} alt="as" className="full" />
                <div className="name" style={{ left: `${left}%` }}>{`${user.nombre}`}</div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="lg" className="mt30"> 
        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
          <Grid item lg={11} md={11} sm={12} xs={12}>
            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
              <Grid item lg={10} md={6} sm={12} xs={12}>
                <div className="titulos">¡Felicidades!</div>
              </Grid>
              <Grid item lg={2} md={6} sm={12} xs={12} className='mt30'>
                {/* <Pdf targetRef={ref} options={options} x={0.5} y={0.2} scale={0.8} filename="certificado.pdf">
                  {({ toPdf }) => (
                    <Button className="btn-download blanco" onClick={printDocument}>
                      Descargar PDF
                    </Button>
                  )}
                </Pdf> */}
                <Button
                  
                  className="btn"
                  onClick={() => { getImage(); }}
                  // style={{ background: "#ff5c93", color: "#fff", width: '150px', margin: 'auto' }}
                >
                  Descargar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
