import React from "react";
import { Grid } from "@material-ui/core";
import scroll from '../../../../resources/images/scroll.gif';


export default function SectionConferenceActive(props) {

  return (
    <div id="section-kick-off" className="wow fadeInUp" data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
      <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
        {/* <Grid item xs={12} sm={12} md={1} lg={1} className='center'>
          <img src={scroll} alt="scroll" className='scroll-gif' />
        </Grid> */}
        <Grid item xs={12} sm={12} md={10} lg={10}>
          <div className="contenedor-streaming">
            <iframe title="streaming" src="https://player.vimeo.com/video/864864162?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" allow="autoplay; fullscreen" allowFullScreen frameBorder="0" className="estilo-streaming"></iframe>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}