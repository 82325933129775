import React from "react";
import { useParams } from "react-router-dom";
import { Grid, Container } from "@material-ui/core";
import julianaDuque from '../../../../../../resources/images/daring/pilares/juliana-duque.svg';

export default function SpeakersConfertencias() {
  const params = useParams();

  return (
    <>
      <Container maxWidth="lg" className="mb30 mt30">
        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
          <Grid item lg={8} md={12} xs={12}>
            <div className="titulos">Acerca del experto</div>
          </Grid>
        </Grid>
      </Container>
      {params.id === 'ejecutivos' && <>
        <Container maxWidth="lg">
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
            <Grid item lg={12} md={12} xs={12} >
              <div className="full">
                <div className="name-conferencista full ">
                  <div >
                    <img className="img-conferencista" src={julianaDuque} alt="img" />
                  </div>
                  <span className="tendencias bold" style={{ display: "inline-block" }}>
                    <span className="job-conferencista">
                      <span className="name">Juliana Duque</span>
                      <span className="description-conferencista">
                        <ul>
                          <li>Consultora Senior, líder de la práctica de Estrategia del Talento para Colombia, Centroamérica y El Caribe en Mercer.</li>
                          <li>Psicóloga con opción en Administración de Empresas de la Universidad de Los Andes, Colombia.</li>
                          <li>Máster en Psicología del Trabajo, las Organizaciones y los Recursos Humanos de la Universidad de Barcelona (España) y Bologna (Italia).</li>
                          <li>Certificada en la metodología de Coaching Ejecutivo y Decodificación de Motivos de Hay Group.</li>
                          <li>Coach Profesional de Esencia por Coaching Hall International (ICF - International Coaching Federation).</li>
                          <li>Docente en Inteligencia Emocional, Liderazgo y otras Habilidades Sociales.</li>
                        </ul>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </>}
      {params.id === 'jefaturas-y-coordinaciones' && <>
        <Container maxWidth="lg" style={{ marginBottom: '60px' }}>
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
            <Grid item lg={12} md={12} xs={12} >
              <div className="full">
                <div className="name-conferencista full ">
                  <div >
                    <img className="img-conferencista" src={julianaDuque} alt="img" />
                  </div>
                  <span className="tendencias bold" style={{ display: "inline-block" }}>
                    <span className="job-conferencista">
                      <span className="name">Juliana Duque</span>
                      <span className="description-conferencista">
                        <ul>
                          <li>Consultora Senior, líder de la práctica de Estrategia del Talento para Colombia, Centroamérica y El Caribe en Mercer.</li>
                          <li>Psicóloga con opción en Administración de Empresas de la Universidad de Los Andes, Colombia.</li>
                          <li>Máster en Psicología del Trabajo, las Organizaciones y los Recursos Humanos de la Universidad de Barcelona (España) y Bologna (Italia).</li>
                          <li>Certificada en la metodología de Coaching Ejecutivo y Decodificación de Motivos de Hay Group.</li>
                          <li>Coach Profesional de Esencia por Coaching Hall International (ICF - International Coaching Federation).</li>
                          <li>Docente en Inteligencia Emocional, Liderazgo y otras Habilidades Sociales.</li>
                        </ul>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </>}

    </>
  );
}
