import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom'
import { Grid, Container, Button } from "@material-ui/core";
import textos from './textos';
import { top } from "../../../../resources/js/functions";
import img from '../../../../resources/images/certificate/aprobado.svg';
import bienestar from '../../../../resources/images/certificate/bienestar.svg';
import autoconocimientoyEmociones from '../../../../resources/images/certificate/autoconocimiento-y-emociones-mal.svg';
import autoconocimientoyEmocionesBien from '../../../../resources/images/certificate/autoconocimiento-y-emociones-bien.svg';
import planeacionyGestionDelTiempo from '../../../../resources/images/certificate/planeacion-y-gestion-del-tiempo-mal.svg';
import planeacionyGestionDelTiempoBien from '../../../../resources/images/certificate/planeacion-y-gestion-del-tiempo-bien.svg';
import bienestarMal from '../../../../resources/images/certificate/bienestar-mal.svg';
import bienestarBien from '../../../../resources/images/certificate/bienestar-bien.svg';

export default function Aprobado(props) {
    const { perfil, seccion1, seccion2, seccion3, activeView, calif } = props;
    const [suma1, setSuma1] = useState(0)
    const [suma2, setSuma2] = useState(0)
    const [suma3, setSuma3] = useState(0)
    const params = useParams();

    useEffect(() => {
        const sumaTotal1 = seccion1[0] + seccion1[1] + seccion1[2] + seccion1[3];
        const sumaTotal2 = seccion2[0] + seccion2[1] + seccion2[2];
        const sumaTotal3 = seccion3[0] + seccion3[1] + seccion3[2];
        setSuma1(sumaTotal1)
        setSuma2(sumaTotal2)
        setSuma3(sumaTotal3)
        top();
    }, [])

    const logo = (perfil) => {
        let logo = ''
        switch (perfil) {
            case "bienestar":
                logo = bienestar;
                break;

            default:
                break;
        }

        return logo;
    }

    return (
        <>
            <div id="aprobado">
                <Container maxWidth="lg">
                    <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <img src={img} alt="img" className="img-top" />
                        </Grid>
                        <Grid item lg={3} md={3} sm={12} xs={12}>
                            <div className="contenedor-calif">
                                Obtuviste<br />
                                {calif}<br />
                                aciertos de 10
                            </div>
                        </Grid>
                    </Grid>
                </Container>
                <div className="retro mt30">
                    <Container maxWidth="lg">
                        <Grid container direction="row">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <img src={logo(perfil)} alt="img" />
                            </Grid>
                        </Grid>
                    </Container>
                    {params.id === 'ejecutivos' && <>
                        <Container maxWidth="lg">
                            <Grid item lg={12} md={10} sm={12} xs={12} justifyContent='center' alignItems="center" >
                                <div className="contenedor-retro">
                                    <Grid container direction="row" spacing={3} alignItems='center' justifyContent="center" className='padre'>
                                        <Grid item lg={2} md={2} sm={12} xs={12} className="hijos center">
                                            <img src={suma1 > 2 ? autoconocimientoyEmocionesBien : autoconocimientoyEmociones} alt="img" />
                                        </Grid>
                                        <Grid item lg={8} md={8} sm={12} xs={12} className="padre">
                                            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2} >
                                                <Grid item lg={4} md={4} sm={12} xs={12} className="hijos padre" >
                                                    <div className="full padre">
                                                        <div className='titulo-retro morado hijos'>
                                                            Manejo de las emociones
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item lg={8} md={8} sm={12} xs={12} className="hijos">
                                                    <p>{suma1 > 2 ? textos[0].autococimiento.bien : textos[0].autococimiento.mal}</p>
                                                </Grid>
                                            </Grid>
                                            <div className="border" />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" spacing={3} alignItems='center' justifyContent="center" className='padre'>
                                        <Grid item lg={2} md={2} sm={12} xs={12} className="hijos center">
                                            <img src={suma2 > 1 ? planeacionyGestionDelTiempoBien : planeacionyGestionDelTiempo} alt="img" />
                                        </Grid>
                                        <Grid item lg={8} md={8} sm={12} xs={12} className="hijos">
                                            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2} >
                                                <Grid item lg={4} md={4} sm={12} xs={12} className="hijos padre">
                                                    <div className="full padre">
                                                        <div className='titulo-retro azul hijos'>
                                                            Priorización y enfoque
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item lg={8} md={8} sm={12} xs={12} className="hijos">
                                                    <p>{suma2 > 1 ? textos[0].planeacion.bien : textos[0].planeacion.mal}</p>
                                                </Grid>
                                            </Grid>
                                            <div className="border" />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" spacing={3} alignItems='center' justifyContent="center" className='padre'>
                                        <Grid item lg={2} md={2} sm={12} xs={12} className="hijos center">
                                            <img src={suma3 > 1 ? bienestarBien : bienestarMal} alt="img" />
                                        </Grid>
                                        <Grid item lg={8} md={8} sm={12} xs={12} className="hijos">
                                            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
                                                <Grid item lg={4} md={4} sm={12} xs={12} className="hijos padre">
                                                    <div className="full padre">
                                                        <div className='titulo-retro verde hijos'>
                                                            Bienestar (mío y de mi equipo)
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item lg={8} md={8} sm={12} xs={12} className="hijos">
                                                    <p>{suma3 > 1 ? textos[0].bienestar.bien : textos[0].bienestar.mal}</p>
                                                </Grid>
                                            </Grid>
                                            <div className="border" />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Container>
                    </>}
                    {params.id === 'jefaturas-y-coordinaciones' && <>
                        <Container maxWidth="lg">
                            <Grid item lg={12} md={10} sm={12} xs={12} justifyContent='center' alignItems="center" >
                                <div className="contenedor-retro">
                                    <Grid container direction="row" spacing={3} alignItems='center' justifyContent="center" className='padre'>
                                        <Grid item lg={2} md={2} sm={12} xs={12} className="hijos center">
                                            <img src={suma1 > 2 ? autoconocimientoyEmocionesBien : autoconocimientoyEmociones} alt="img" />
                                        </Grid>
                                        <Grid item lg={8} md={8} sm={12} xs={12} className="padre">
                                            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2} >
                                                <Grid item lg={4} md={4} sm={12} xs={12} className="hijos padre" >
                                                    <div className="full padre">
                                                        <div className='titulo-retro morado hijos'>
                                                            Autoconocimiento
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item lg={8} md={8} sm={12} xs={12} className="hijos">
                                                    <p>{suma1 > 2 ? textos[1].autococimiento.bien : textos[1].autococimiento.mal}</p>
                                                </Grid>
                                            </Grid>
                                            <div className="border" />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" spacing={3} alignItems='center' justifyContent="center" className='padre'>
                                        <Grid item lg={2} md={2} sm={12} xs={12} className="hijos center">
                                            <img src={suma2 > 1 ? planeacionyGestionDelTiempoBien : planeacionyGestionDelTiempo} alt="img" />
                                        </Grid>
                                        <Grid item lg={8} md={8} sm={12} xs={12} className="hijos">
                                            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2} >
                                                <Grid item lg={4} md={4} sm={12} xs={12} className="hijos padre">
                                                    <div className="full padre">
                                                        <div className='titulo-retro azul hijos'>
                                                            Planeación
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item lg={8} md={8} sm={12} xs={12} className="hijos">
                                                    <p>{suma2 > 1 ? textos[1].planeacion.bien : textos[1].planeacion.mal}</p>
                                                </Grid>
                                            </Grid>
                                            <div className="border" />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" spacing={3} alignItems='center' justifyContent="center" className='padre'>
                                        <Grid item lg={2} md={2} sm={12} xs={12} className="hijos center">
                                            <img src={suma3 > 1 ? bienestarBien : bienestarMal} alt="img" />
                                        </Grid>
                                        <Grid item lg={8} md={8} sm={12} xs={12} className="hijos">
                                            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
                                                <Grid item lg={4} md={4} sm={12} xs={12} className="hijos padre">
                                                    <div className="full padre">
                                                        <div className='titulo-retro verde hijos'>

                                                            Bienestar (mío y de mi equipo)
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item lg={8} md={8} sm={12} xs={12} className="hijos">
                                                    <p>{suma3 > 1 ? textos[1].bienestar.bien : textos[1].bienestar.mal}</p>
                                                </Grid>
                                            </Grid>
                                            <div className="border" />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Container>
                    </>}
                </div>
                <Container maxWidth="lg" className="mt30">
                    <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Grid container direction="row" alignItems="center" spacing={3}>
                                <Grid item lg={9} md={6} sm={12} xs={12}>
                                    <p>Aprovecha lo que ahora sabes y ponlo en práctica diariamente. Y si necesitas reforzar algún concepto, siempre puedes volver a consultar los recursos digitales.</p>
                                    <p>Ahora da clic en el botón para generar tu constancia.</p>
                                </Grid>
                                <Grid item lg={3} md={6} sm={12} xs={12} className='mt30'>
                                    <Button className="btn" onClick={() => activeView(1)} style={{ width: '100%' }}>Generar constancia</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    );
}

