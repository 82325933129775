import React from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Img from '../../../../resources/images/pilares/bienestar/marcella-plata.svg';

export default function SpeakersConfertencias() {
  const params = useParams();

  return (
    <>
      <Grid item lg={8} md={12} xs={12}>
        <div className="titulos">Acerca del experto</div>
      </Grid>
      <Grid item lg={8} md={12} xs={12} >
        <div className="full">
          <div className="name-conferencista full ">
            <div >
              <img className="img-conferencista" src={Img} alt="img" />
            </div>
            <span className="tendencias bold" style={{ display: "inline-block" }}>
              <span className="job-conferencista">
                <span className="name">{params.id === 'ejecutivos' ? 'Marcella Plata' : 'Marcella Plata'}</span>
                <span className="description-conferencista">
                  {
                    params.id === 'ejecutivos' ?
                      <ul>
                        <li>Maestría en Liderazgo Positivo</li>
                        <li>Coach</li>
                        <li>Instructora de programas de capacitación</li>
                        <li> Diseño e impartición de talleres vivenciales</li>
                      </ul> :
                      <ul>
                        <li>Maestría en Liderazgo Positivo</li>
                        <li>Coach</li>
                        <li>Instructora de programas de capacitación</li>
                        <li> Diseño e impartición de talleres vivenciales</li>
                      </ul>
                  }
                </span>
              </span>
            </span>
          </div>
        </div>
      </Grid>
    </>
  );
}
