import React, { useEffect, useState } from 'react';
import { ref, onChildChanged } from "firebase/database";
import { db } from "../../../config/firebase";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Paper } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Header from '../../../components/HeaderAdmin';
import ValidateAdmin from '../../../components/ValidateAdmin';
import { getCollections, deleteData } from '../../../resources/js/functions';
import './styles.scss';

const columns = [
    { testimonio: 'testimonio', label: 'Testimonio', minWidth: 170 },
];

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
});

export default function Usuarios() {
    const classes = useStyles();
    const [rowsTable, setRowsTable] = useState([]);
    // const [searched, setSearched] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const navigate = useNavigate();

    useEffect(() => {
        getTestimonios();
        const dbRef = ref(db, "testimonios/");
        onChildChanged(dbRef, (data) => {
            getTestimonios();
        });
    }, []);

    const getTestimonios = async () => {
        const data = await getCollections('testimonios');
        setRowsTable(data);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const borrarTestimonio = async (id) => {
        Swal.fire({
            title: "Advertencia!",
            text: "¿Desea eliminar este testimonio?",
            icon: "warning",
            confirmButtonText: "Confirmar",
            confirmButtonColor: "#2D6DB4",
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            showCancelButton: true
        }).then((result) => {
            if (result.isConfirmed) {
                deleteData('testimonios', id).then(() => {
                    Swal.fire({
                        title: "Bien hecho",
                        text: "Testimonio eliminado correctamente",
                        icon: "success",
                        confirmButtonText: "Confirmar",
                        confirmButtonColor: "#2D6DB4",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            getTestimonios();
                        }
                    })
                }).catch(e => console.log(e))
            }
        })
    }

    return <>
        <ValidateAdmin>
            <Header>
                <div id="testimoniosPanel">
                    <Container maxWidth="lg" >
                        <Paper elevation={3} style={{ padding: '15px' }}>
                            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <h1>Testimonios</h1>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    {/* <InputSearch busquedaProps={busquedaFunction} /> */}
                                    <TableContainer className={classes.container}>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map((column) => (
                                                        <TableCell
                                                            key={column.numEmpleados}
                                                            align={column.align}
                                                            style={{ minWidth: column.minWidth }}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                    <TableCell>
                                                        Editar
                                                    </TableCell>
                                                    <TableCell>
                                                        Borrar
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rowsTable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                            {columns.map((column) => {
                                                                const value = row[column.testimonio];
                                                                console.log(column.testimonio);
                                                                return (
                                                                    <TableCell key={column.id}>
                                                                        {!value ? '-' : value}
                                                                    </TableCell>
                                                                );
                                                            })}
                                                            <TableCell onClick={() => navigate(`/admin/testimonios/${row.id}/actualizar`)} className='center cursor'>
                                                                <EditIcon />
                                                            </TableCell>
                                                            <TableCell onClick={() => borrarTestimonio(row.id)} className='center cursor'>
                                                                <DeleteIcon />
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        labelRowsPerPage={"Resultados por página"}
                                        labelDisplayedRows={
                                            ({ from, to, count }) => {
                                                return '' + from + '-' + to + ' de ' + count
                                            }
                                        }
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={rowsTable.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Container>
                </div>
            </Header >
        </ValidateAdmin>
    </>
}