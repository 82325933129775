import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from "@material-ui/core";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import logo from "../../../resources/images/login/logo.svg";
import logo2 from "../../../resources/images/login/logo-2.svg";

export default function SectionWelcome(props) {
  const navigate = useNavigate();
  return (
    <Grid item xs={12} sm={6} md={5} lg={5} className="wow fadeInLeft" data-wow-delay='.2s' data-wow-offset='0' data-wow-duration='.5s'>
      <div className="fullHeigth container-logo">
      {/* <img src={logo} alt="logo" className='logo-welcome' /> */}
        <div className="overlap">
          <div className='arrow-mobile up-to-down center'>
            <KeyboardDoubleArrowDownIcon />
          </div>
          <div className="full center ">
            <img src={logo} alt="logo" className='logo-welcome' />
            <div className="welcome-text full center ">
            <img src={logo2} alt="logo" className='logo-welcome' />
              <h1>¡El liderazgo del balance!</h1>
              
              {/* <div className="full center">
                <p>El liderazgo del balance</p>
                <p><Button className="cursor btn-naranja blanco" onClick={() => activeModal(true)}>Ver agenda</Button></p>
                <p>FALTAN:</p>
                <p>{`${fecha.dia} días, ${fecha.hora} horas, ${fecha.minutos} minutos, ${fecha.segundos} segundos`}</p> 
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
}
