export default function ValidateUser(props) {
	const { children } = props;
	const data = localStorage.getItem('531ac50224f238df5d6efdaf36507cf2');
	let content = '';

	if (
		data === 'olga.gonzalez@heineken.com' ||
		data === 'christian.leija@heineken.com' ||
		data === 'blanca.gonzalez@heineken.com' ||
		data === 'luis.medina@spira.com.mx' ||
		data === 'abner.pineda@spira.com.mx' ||
		data === 'samuel.bravo@spira.com.mx' ||
		data === 'melissa.valdez@heineken.com' ||
		data === 'eliana.reyes@heineken.com' ||
		data === 'olman.gutierrez@spira.com.mx'
	) {
		content = <div>{children}</div>;
	} else {
		window.location.replace('/admin/login');
	}
	return content;
}
