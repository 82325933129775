import React from 'react';
import { Grid, Container } from "@material-ui/core";
import img1 from '../../../../../resources/images/broshure/seccion18/img1.svg';


export default function Seccion18() {

    return <section id="seccion18" className='wow fadeInUp' data-wow-delay='.2s' data-wow-offset='0' data-wow-duration='.5s'>
        <Container maxWidth="lg" >
            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={12} md={10} lg={10}>
                    <div className="center">
                        <img src={img1} alt="img" className='mb30 size' />
                    </div>
                </Grid>
            </Grid>
        </Container>
    </section>
}