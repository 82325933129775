import React, { useState, useEffect } from 'react';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { Container, Grid, TextField, MenuItem, Button, Paper, FormControlLabel, Switch } from '@material-ui/core';
import uniqid from 'uniqid';
import Swal from "sweetalert2";
import Header from '../../../../components/HeaderAdmin';
import ValidateAdmin from '../../../../components/ValidateAdmin';
import { getDate, setData, searchDataByValue } from '../../../../resources/js/functions'
import './styles.scss';

export default function CrearUsurios() {
    const [campos, setCampos] = useState({activo:false});
    const [password, setPassword] = useState(null);
    const auth = getAuth();

    useEffect(() => {
        setPassword(uniqid())
    }, [])

    const validar = async e => {
        e.preventDefault();
        let json = {}
        json = {
            ...campos,
            fechaRegistro: getDate(),
            playbook: false,
        };
        const existCorreo = await searchDataByValue("users", "id", campos.id);
        if (Object.keys(existCorreo).length > 0) {
            Swal.fire({
                title: "Precaución!",
                text: "Este usuario ya se encuentra registrado",
                icon: "warning",
                confirmButtonText: "Cerrar",
                confirmButtonColor: "#2D6DB4",
            });
        } else {

            setData("users", campos.id, json).then(() => {
                Swal.fire({
                    title: "¡Registro exitoso!",
                    html: `El usuario se registro correctamente`,
                    icon: "success",
                    confirmButtonText: "Cerrar",
                    confirmButtonColor: "#2D6DB4",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
            })

        }
    }

    return <>
        <ValidateAdmin>
            <Header>
                <div id='crearUsuarios'>
                    <form onSubmit={validar}>
                        <Container maxWidth="lg">
                            <Paper elevation={3} style={{ padding: '15px' }}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <h1>Registro de usuarios</h1>
                                    </Grid>

                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <TextField required autoComplete="true" onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }} fullWidth id="numEmpleado" label="Número de Empleado" variant="outlined" onChange={(e) => setCampos({ ...campos, id: e.target.value })} />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <TextField required autoComplete="true" onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }} fullWidth id="numEmpleado" label="ID Global" variant="outlined" onChange={(e) => setCampos({ ...campos, idGlobal: e.target.value })} />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <TextField required fullWidth id="nombre" label="Nombre" variant="outlined" onChange={(e) => setCampos({ ...campos, nombre: e.target.value })} />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <TextField required fullWidth id="puesto" label="Puesto" variant="outlined" onChange={(e) => setCampos({ ...campos, puesto: e.target.value })} />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <TextField required fullWidth id="nombreLineManager" label="Nombre Completo del Line Manager" variant="outlined" onChange={(e) => setCampos({ ...campos, nombreLineManager: e.target.value })} />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <TextField select required fullWidth id="audiencia" label="Audiencia" variant="outlined" onChange={(e) => setCampos({ ...campos, audiencia: e.target.value })}>
                                            <MenuItem value='Ejecutivos'>Ejecutivos</MenuItem>
                                            <MenuItem value='Jefaturas y Coordinaciones'>Jefaturas y Coordinaciones</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <TextField required fullWidth id="statusLineManager" label="Estatus Line Manager" variant="outlined" onChange={(e) => setCampos({ ...campos, statusLineManager: e.target.value })} />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <TextField required fullWidth type="email" id="correo" label="Correo" variant="outlined" onChange={(e) => setCampos({ ...campos, correo: e.target.value })} />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <TextField required fullWidth id="j1" label="J1" variant="outlined" onChange={(e) => setCampos({ ...campos, j1: e.target.value })} />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <TextField required fullWidth id="j2" label="J2" variant="outlined" onChange={(e) => setCampos({ ...campos, j2: e.target.value })} />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <TextField required fullWidth id="j3" label="J3" variant="outlined" onChange={(e) => setCampos({ ...campos, j3: e.target.value })} />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <TextField required fullWidth id="j4" label="J4" variant="outlined" onChange={(e) => setCampos({ ...campos, j4: e.target.value })} />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <TextField required fullWidth id="j5" label="J5" variant="outlined" onChange={(e) => setCampos({ ...campos, j5: e.target.value })} />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <FormControlLabel
                                            
                                            control={<Switch onChange={(e) => setCampos({ ...campos, activo: e.target.checked })} defaultChecked={campos.activo}  color="primary" />}
                                            label="Activo"
                                            labelPlacement="top"
                                        />
                                    </Grid>
                                    {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                                <TextField fullWidth id="password" InputLabelProps={{ shrink: true }} label="Contraseña" value={password} disabled variant="outlined" />
                            </Grid> */}
                                    <Grid item lg={12} md={6} sm={12} xs={12}>
                                        <div className="full center">
                                            <Button
                                                type='submit'
                                                className="btn"
                                            // style={{ color: "#fff", width: '150px', margin: 'auto' }}
                                            >
                                                Registrar
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Container>
                    </form>
                </div>
            </Header>
        </ValidateAdmin>
    </>
}