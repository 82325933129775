import React, { useState, useEffect } from "react";
import { db } from "../../../../../config/firebase";
import { ref, onChildChanged } from "firebase/database";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Container } from "@material-ui/core";
import ValidateUser from "../../../../../components/ValidateUser";
import Header from "../../../../../components/Header";
import Footer from "../../../../../components/Footer";
import SpeakersConferencias from "./SpeakersConferencias";
import carlos from '../../../../../resources/images/pilares/gestion/carlos-adrian.svg';
import talina from '../../../../../resources/images/pilares/gestion/talina-gonzales.svg';
import { getCollectionsComplete, top, } from "../../../../../resources/js/functions";
import { ejectutivos, jefaturasyCoordinadores } from '../informacion';
import "./styles.scss";

export default function MasterClass() {
  const navigate = useNavigate();
  const params = useParams();
  const [informacion, setinformacion] = useState({})
  const [audiencia, setAudiencia] = useState('Ejecutivos');
  const dbRef = ref(db, "configuracionPilares/inclusion/");

  useEffect(() => {
    top();
    getInfo();
    onChildChanged(dbRef, (data) => {
      getInfo();
    });
  }, []);

  const getInfo = async () => {
    const data = await getCollectionsComplete('configuracionPilares/inclusion');
    if (data) {
      setAudiencia(data.audiencia);
      setinformacion(data);
    }
  }

  const url = audiencia === 'ejecutivos' ? "https://player.vimeo.com/video/866444689?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" : "https://player.vimeo.com/video/866443019?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"

  return (
    <>
      <ValidateUser>
        <Header />
        <div id="masterClass">
          <Container maxWidth="lg">
            <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={2}>
              <Grid item lg={12} md={12} xs={12}>
                <div className="cursor" onClick={() => navigate(-1)}>{'< Regresar'}</div>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <div className="titulos">{url === 'ejecutivos' ? `Master Class: ${ejectutivos.masterClass.titulo}` : `Master Class: ${jefaturasyCoordinadores.masterClass.titulo}`}</div>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                {url === 'ejecutivos' ?
                  <p>{ejectutivos.masterClass.descripcion}</p> :
                  <p>{jefaturasyCoordinadores.masterClass.descripcion}</p>
                }
              </Grid>
              {/* <Grid item lg={8} md={12} xs={12}>
                <div className="contenedor-streaming">
                  <iframe title="streaming" src={url} allow="autoplay; fullscreen" frameBorder="0" allowFullScreen className="estilo-streaming"></iframe>
                </div>
              </Grid>
              {informacion.chatEjecutivos && <Grid item lg={4} md={6} xs={12}>
                <div className="contentenedor-chat">
                  <iframe title="chat" src={informacion.urlChatEjecutivos} width="100%" height="100%" frameBorder="0"></iframe>
                </div>
              </Grid>}
              {informacion.chatJefaturasCoordinaciones && <Grid item lg={4} md={6} xs={12}>
                <div className="contentenedor-chat">
                  <iframe title="chat" src={informacion.urlChatJefaturasCoordinaciones} width="100%" height="100%" frameBorder="0"></iframe>
                </div>
              </Grid>}
              <SpeakersConferencias /> */}
            </Grid>
          </Container>


          {params.id === 'ejecutivos' && <Container maxWidth="lg">
            <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={2}>
              <Grid item lg={8} md={12} xs={12}>
                <div className="contenedor-streaming">
                  <iframe title="streaming" src="https://player.vimeo.com/video/866444689?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" allow="autoplay; fullscreen" frameBorder="0" allowFullScreen className="estilo-streaming"></iframe>
                </div>
              </Grid>
              <Grid item lg={8} md={12} xs={12}>
                <div className="titulos">Acerca del experto</div>
              </Grid>
              <Grid item lg={8} md={12} xs={12} >
                <div className="full">
                  <div className="name-conferencista full ">
                    <div >
                      <img className="img-conferencista" src={talina} alt="img" />
                    </div>
                    <span className="tendencias bold" style={{ display: "inline-block" }}>
                      <span className="job-conferencista">
                        <span className="name">Talina González</span>
                        <span className="description-conferencista">
                          <ul>
                            <li>People & Organizational Development Director</li>
                          </ul>
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>}

          {params.id === 'jefaturas-y-coordinaciones' && <Container maxWidth="lg">
            <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={2}>
              <Grid item lg={8} md={12} xs={12}>
                <div className="contenedor-streaming">
                  <iframe title="streaming" src="https://player.vimeo.com/video/866443019?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" allow="autoplay; fullscreen" frameBorder="0" allowFullScreen className="estilo-streaming"></iframe>
                </div>
              </Grid>
              <Grid item lg={8} md={12} xs={12}>
                <div className="titulos">Acerca del experto</div>
              </Grid>
              <Grid item lg={8} md={12} xs={12} >
                <div className="full">
                  <div className="name-conferencista full ">
                    <div >
                      <img className="img-conferencista" src={talina} alt="img" />
                    </div>
                    <span className="tendencias bold" style={{ display: "inline-block" }}>
                      <span className="job-conferencista">
                        <span className="name">Talina González</span>
                        <span className="description-conferencista">
                          <ul>
                            <li>People & Organizational Development Director</li>
                          </ul>
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>}

          <Container maxWidth="lg">
            <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={2}>
              <Grid item lg={12} md={12} xs={12} className='center'>
                <div className="cursor" onClick={() => navigate(-1)}>{'< Regresar'}</div>
              </Grid>
            </Grid>
          </Container>
          <Footer />
        </div>
      </ValidateUser>
    </>
  );
}
