import tutorial from '../../../../resources/images/daring/pilares/tutorial-narrador.png';
import podcast from '../../../../resources/images/daring/pilares/podcast-narrador.png';
import evaluacion from '../../../../resources/images/daring/pilares/evaluacion-narrador.png';
import portinsignia from '../../../../resources/images/daring/pilares/portada-insignia-narrador.png';
const ejectutivos = {
	tutorial1: {
		portada: tutorial,
		titulo: 'Clase tutorial de las competencias Narrador y Activador',
		descripcion: 'Conoce cómo incluir y fortalecer las competencias Narrador y Activador en tu liderazgo',
		urlInsumo: '/daring/ejecutivos/narrador/tutorial',
		url: 'https://player.vimeo.com/video/876508521?h=de557a03a5&title=0&byline=0&portrait=0',
	},
	podcast1: {
		titulo: 'Podcast de las competencias Narrador y Activador',
		descripcion: 'Conoce cómo un experto aplica las competencias narrador y activador en su cotidianidad como líder y su punto de vista al ponerlo en práctica',
		urlInsumo: '/daring/ejecutivos/narrador/podcast',
		portada: podcast,
		url: 'https://player.vimeo.com/video/880058581?h=a2b0dfc96c&title=0&byline=0&portrait=0',
	},
	evaluacion: {
		portada: evaluacion,
		url: '',
		titulo: '',
		descripcion: '',
		urlInsumo: '/daring/ejecutivos/narrador/evaluacion',
	},
	insignia: {
		portada: portinsignia,
		titulo: 'Insignia',
		descripcion: '',
		urlInsumo: '',
		url: '/daring/ejecutivos/narrador/insignia',
	},
};
const jefaturasyCoordinadores = {
	tutorial1: {
		titulo: 'Clase tutorial de las competencias Narrador y Activador',
		descripcion: 'Conoce cómo incluir y fortalecer las competencias Narrador y Activador en tu liderazgo',
		urlInsumo: '/daring/jefaturas-y-coordinaciones/narrador/tutorial',
		portada: tutorial,
		url: 'https://player.vimeo.com/video/880046687?h=7fbc4773a4&title=0&byline=0&portrait=0',
	},
	podcast1: {
		titulo: 'Podcast de las competencias Narrador y Activador',
		descripcion: 'Conoce cómo un experto aplica las competencias narrador y activador en su cotidianidad como líder y su punto de vista al ponerlo en práctica',
		urlInsumo: '/daring/jefaturas-y-coordinaciones/narrador/podcast',
		portada: podcast,
		url: 'https://player.vimeo.com/video/880058581?h=a2b0dfc96c&title=0&byline=0&portrait=0',
	},
	evaluacion: {
		portada: evaluacion,
		url: '',
		titulo: 'Evaluación Narrador Activador',
		descripcion: '',
		urlInsumo: '/daring/jefaturas-y-coordinaciones/narrador/evaluacion',
	},
	insignia: {
		portada: portinsignia,
		titulo: 'Insignia',
		descripcion: '',
		urlInsumo: '',
		url: '/daring/ejecutivos/narrador/insignia',
	},
};

export { ejectutivos, jefaturasyCoordinadores };
