import React from 'react';
import { PropTypes } from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import './styles.scss';

const ModalComponent = ({ children, ...props }) => {
	return (
		<Dialog
			PaperProps={{
				style: {
					backgroundColor: 'transparent',
					color: 'black',
					boxShadow: 'none',
				},
			}}
			open={props.open}
			onClose={() => {}}
			{...props}
			aria-labelledby='customized-dialog-title'
		>
			{children}
		</Dialog>
	);
};

ModalComponent.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

ModalComponent.defaultProps = {
	children: null,
};

export default ModalComponent;
