import React, { useState } from 'react';
import { Grid, Container } from "@material-ui/core";
import CloseIcon from '@mui/icons-material/Close';
import Modal from '../../../../../../components/Modal';
import cuadro1 from '../../../../../../resources/images/broshure/seccion11/comportamientos/cuadro1.svg'
import cuadro2 from '../../../../../../resources/images/broshure/seccion11/comportamientos/cuadro2.svg'
import cuadro3 from '../../../../../../resources/images/broshure/seccion11/comportamientos/cuadro3.svg'
import cuadro4 from '../../../../../../resources/images/broshure/seccion11/comportamientos/cuadro4.svg'
import cuadro5 from '../../../../../../resources/images/broshure/seccion11/comportamientos/cuadro5.svg'
import cuadro6 from '../../../../../../resources/images/broshure/seccion11/comportamientos/cuadro6.svg'
import cuadro7 from '../../../../../../resources/images/broshure/seccion11/comportamientos/cuadro7.svg'
import cuadro8 from '../../../../../../resources/images/broshure/seccion11/comportamientos/cuadro8.svg'
import cuadro9 from '../../../../../../resources/images/broshure/seccion11/comportamientos/cuadro9.svg'
import cuadro10 from '../../../../../../resources/images/broshure/seccion11/comportamientos/cuadro10.svg'
import popupContenido from '../../../../../../resources/images/broshure/seccion11/comportamientos/popup-comportamientos.svg'
import imgContenido from '../../../../../../resources/images/broshure/seccion11/bienestar/img-contenido.svg'
import textoContenido from '../../../../../../resources/images/broshure/seccion11/bienestar/texto-contenido.svg'
import btnContenido from '../../../../../../resources/images/broshure/seccion11/bienestar/btn-contenido.svg'
import imgExpertos from '../../../../../../resources/images/broshure/seccion11/bienestar/img-expertos.svg'
import textoExpertos from '../../../../../../resources/images/broshure/seccion11/bienestar/texto-expertos.svg'
import btnExpertos from '../../../../../../resources/images/broshure/seccion11/bienestar/btn-expertos.svg'

export default function Comportamientos() {
    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);

    return (
        <div id="comportamientos">
            <Container maxWidth="lg">
                <Grid container direction="row" alignItems="stretch" spacing={0}>
                    <Grid item xs={12} sm={12} md={8} lg={8} className='center wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        <div className='border-c1'>
                            <img src={cuadro1} alt="img" className='full' />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} className='padre wow zoomInRight' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        <div className='center hijos border-c2' style={{ background: '#fff' }}>
                            <div className="cuadro-blanco">
                                <img src={cuadro2} alt="img" className='cuadro2TextoComportamientos' />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} className='border-c3 wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        <img src={cuadro3} alt="img" className='full' />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} className='border-c3 wow zoomInRight' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        <img src={cuadro4} alt="img" className='full' />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} className='border-c3 wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        <img src={cuadro5} alt="img" className='full' />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} className='border-c3 wow zoomInRight' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        <img src={cuadro6} alt="img" className='full' />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} className='border-c3  wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        <img src={cuadro7} alt="img" className='full' />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} className='border-c3 wow zoomInRight' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        <img src={cuadro8} alt="img" className='full' />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} className='border-c3  wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        <img src={cuadro9} alt="img" className='full' />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} className='border-c3 wow zoomInRight' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        <img src={cuadro10} alt="img" className='full' />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6} className='border-c5 wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>

                        <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={0} style={{ padding: '30px' }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} className='center'>
                                <img src={imgContenido} alt="img" style={{ marginRight: '20px', marginBottom: '20px' }} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className='center'>
                                <img src={textoContenido} alt="img" /><br /><br />
                                <img className='cursor' src={btnContenido} alt="img" onClick={() => setModal(true)} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} className='border-c6 wow zoomInRight' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={0} style={{ padding: '30px' }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} className='center'>
                                <img src={imgExpertos} alt="img" style={{ marginRight: '20px', marginBottom: '20px' }} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className='center'>
                                <img src={textoExpertos} alt="img" /><br /><br />
                                <img className='cursor' src={btnExpertos} alt="img" onClick={() => setModal2(true)} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Modal open={modal}>
                <Container maxWidth="lg">
                    <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={0} style={{ padding: '30px' }}>
                        <div className='full' >
                            <div className='cursor btn-cerrar-popup' onClick={() => setModal(false)}>
                                Cerrar
                            </div>
                        </div>
                        <img src={popupContenido} alt="img" className="" />
                    </Grid>
                </Container>
            </Modal>
            <Modal open={modal2}>
                <Container maxWidth="lg">
                    <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={0} style={{ padding: '30px' }}>
                        <div className='full' >
                            <div className='cursor btn-cerrar-popup' onClick={() => setModal2(false)}>
                                Cerrar
                            </div>
                        </div>
                        <img src={popupContenido} alt="img" className="" />
                    </Grid>
                </Container>
            </Modal>
        </div >
    )
}