import React from 'react';
import { useNavigate } from 'react-router-dom'
import { Grid, Container } from "@material-ui/core";
import { download_file, SaveToDisk } from '../../../../../resources/js/functions'
import ComportamientosBrochure from '../../../Broshure/RolUno/Seccion11/Comportamientos';
import PDF from '../../../../../resources/descargables/agenda.pdf'
import PortadaMasterClass from '../../../../../resources/images/pilares/bienestar/portada-master-class.svg';
import PortadaVideoClass from '../../../../../resources/images/pilares/bienestar/portada-video-tutorial.svg';
import podcast from '../../../../../resources/images/pilares/bienestar/podcast.svg';
import playbook from '../../../../../resources/images/pilares/bienestar/playbook.svg';
import bienestar from '../../../../../resources/images/pilares/bienestar/bienestar.svg';
import evaluacion from '../../../../../resources/images/pilares/bienestar/evaluacion.svg';
import visto from '../../../../../resources/images/pilares/bienestar/visto.svg';
import noVisto from '../../../../../resources/images/pilares/bienestar/no-visto.svg';
import scroll from '../../../../../resources/images/scroll.gif';

export default function Comportamientos() {
    const navigate = useNavigate();

    return (
        <>
            <div id="comportamientos">
                <Container maxWidth="lg">
                    <Grid container direction="row" alignItems="center" justifyContent='center' spacing={2}>
                        <Grid item xs={12} sm={12} md={1} lg={1} className='wow zoomInLeft center' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                            <img src={scroll} alt="scroll" className='scroll-gif' />
                        </Grid>
                    </Grid>
                </Container>
                <ComportamientosBrochure />
            </div >
        </>
    )
}