import React from "react";
import { useParams } from "react-router-dom";
import { Grid, Container } from "@material-ui/core";
import dianaMoreno from '../../../../../../resources/images/daring/pilares/diana-moreno.svg';

export default function SpeakersConfertencias() {
  const params = useParams();

  return (
    <>
      <Container maxWidth="lg" className="mb30 mt30">
        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
          <Grid item lg={8} md={12} xs={12}>
            <div className="titulos">Acerca del experto</div>
          </Grid>
        </Grid>
      </Container>
      {params.id === 'ejecutivos' && <>
        <Container maxWidth="lg">
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
            <Grid item lg={12} md={12} xs={12} >
              <div className="full">
                <div className="name-conferencista full ">
                  <div >
                    <img className="img-conferencista" src={dianaMoreno} alt="img" />
                  </div>
                  <span className="tendencias bold" style={{ display: "inline-block" }}>
                    <span className="job-conferencista">
                      <span className="name">Diana Moreno</span>
                      <span className="description-conferencista">
                        <ul>
                          <li>Consultora senior especializada en proyectos con más de 20 años de experiencia en diversos sectores, principalmente en el ámbito financiero.</li>
                          <li>Licenciada en Pedagogía con especialidad en Recursos Humanos, y con estudios de postgrado en Desarrollo Cognitivo y Emocional, Management y Coach Ejecutivo certificado.</li>
                          <li>Certificación en Hogan Assessments N1 y Certificación honorífica en Creatividad e Innovación por la Universidad de Búfalo y CCC.</li>
                          <li>Colaboró con Bital y HSBC (México y LATAM) del 2000 al 2015 teniendo múltiples responsabilidades, desde Asesor de Desarrollo Organizacional y hasta Senior VP para la implementación de estrategias globales.</li>
                        </ul>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </>}
      {params.id === 'jefaturas-y-coordinaciones' && <>
        <Container maxWidth="lg" style={{ marginBottom: '60px' }}>
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
            <Grid item lg={12} md={12} xs={12} >
              <div className="full">
                <div className="name-conferencista full ">
                  <div >
                    <img className="img-conferencista" src={dianaMoreno} alt="img" />
                  </div>
                  <span className="tendencias bold" style={{ display: "inline-block" }}>
                    <span className="job-conferencista">
                      <span className="name">Diana Moreno</span>
                      <span className="description-conferencista">
                        <ul>
                          <li>Consultora senior especializada en proyectos con más de 20 años de experiencia en diversos sectores, principalmente en el ámbito financiero.</li>
                          <li>Licenciada en Pedagogía con especialidad en Recursos Humanos, y con estudios de postgrado en Desarrollo Cognitivo y Emocional, Management y Coach Ejecutivo certificado.</li>
                          <li>Certificación en Hogan Assessments N1 y Certificación honorífica en Creatividad e Innovación por la Universidad de Búfalo y CCC.</li>
                          <li>Colaboró con Bital y HSBC (México y LATAM) del 2000 al 2015 teniendo múltiples responsabilidades, desde Asesor de Desarrollo Organizacional y hasta Senior VP para la implementación de estrategias globales.</li>
                        </ul>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </>}

    </>
  );
}
