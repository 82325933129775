import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';

const useStyles = makeStyles((theme) => ({
	nested: {
		paddingLeft: theme.spacing(4),
	},
}));

export default function MenuSuperAdministrador() {
	const classes = useStyles();
	const navigate = useNavigate();
	const [openList, setOpenList] = React.useState(false);
	const [openList2, setOpenList2] = React.useState(false);

	useEffect(() => {
		setOpenList(
			window.location.pathname === '/admin/usuarios' ||
				window.location.pathname === '/admin/usuarios/crear' ||
				window.location.pathname === '/admin/usuarios/carga'
				? true
				: false
		);
		setOpenList2(window.location.pathname === '/admin/testimonios' ||window.location.pathname === '/admin/testimonios/crear'? true: false
		);
	}, []);

	return (
		<>
			<Divider />
			<List
				subheader={
					<ListSubheader component='div' id='estadisticas'>
						Administración
					</ListSubheader>
				}
			>
				<ListItem button className='titulo-menu' onClick={() => setOpenList(!openList)}>
					<ListItemIcon>
						<PeopleAltIcon />{' '}
					</ListItemIcon>
					<ListItemText primary='Usuarios' />
					{openList ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={openList} timeout='auto' unmountOnExit>
					<List component='div' disablePadding>
						<ListItem
							button
							onClick={() => navigate('/admin/usuarios')}
							className={`titulo-menu ${classes.nested} ${window.location.pathname === '/admin/usuarios' ? 'active' : ''}`}
						>
							<ListItemText primary='Visualizar' />
						</ListItem>
						<ListItem
							button
							onClick={() => navigate('/admin/usuarios/crear')}
							className={`titulo-menu ${classes.nested} ${window.location.pathname === '/admin/usuarios/crear' ? 'active' : ''}`}
						>
							<ListItemText primary='Crear' />
						</ListItem>
						<ListItem
							button
							onClick={() => navigate('/admin/usuarios/carga')}
							className={`titulo-menu ${classes.nested} ${window.location.pathname === '/admin/usuarios/carga' ? 'active' : ''}`}
						>
							<ListItemText primary='Carga masiva' />
						</ListItem>
					</List>
				</Collapse>
				<ListItem button className='titulo-menu' onClick={() => setOpenList2(!openList2)}>
					<ListItemIcon>
						<QuestionAnswerIcon />{' '}
					</ListItemIcon>
					<ListItemText primary='Testimonios' />
					{openList2 ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={openList2} timeout='auto' unmountOnExit>
					<List component='div' disablePadding>
						<ListItem
							button
							onClick={() => navigate('/admin/testimonios')}
							className={`titulo-menu ${classes.nested} ${window.location.pathname === '/admin/testimonios' ? 'active' : ''}`}
						>
							<ListItemText primary='Visualizar' />
						</ListItem>
						<ListItem
							button
							onClick={() => navigate('/admin/testimonios/crear')}
							className={`titulo-menu ${classes.nested} ${window.location.pathname === '/admin/testimonios/crear' ? 'active' : ''}`}
						>
							<ListItemText primary='Crear' />
						</ListItem>
					</List>
				</Collapse>
			</List>
		</>
	);
}
