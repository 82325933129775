import btn1 from '../../../../../../../resources/images/broshure/seccion11/gestion/mobile/btn1.svg';
import btn2 from '../../../../../../../resources/images/broshure/seccion11/gestion/mobile/btn2.svg';
import btn3 from '../../../../../../../resources/images/broshure/seccion11/gestion/mobile/btn3.svg';
import btn4 from '../../../../../../../resources/images/broshure/seccion11/gestion/mobile/btn4.svg';
import btn5 from '../../../../../../../resources/images/broshure/seccion11/gestion/mobile/btn5.svg';
import btn6 from '../../../../../../../resources/images/broshure/seccion11/gestion/mobile/btn6.svg';
import btn7 from '../../../../../../../resources/images/broshure/seccion11/gestion/mobile/btn7.svg';

const btns = [btn1, btn2, btn3, btn4, btn5, btn6, btn7];

export default btns;
