import React from 'react';
import InclusionPilar from '../../../Pilares/Inclusion';

export default function Inclusion() {
    return (
        <>
            <div id="inclusion">
                <div id="recursos-digitales">
                    <InclusionPilar />
                </div>
            </div>
        </>
    )
}