const questionario = {
	pregunta1: {
		pregunta: '1.	¿Qué acciones debe realizar un líder para transformar a la organización?',
		opcionA: 'a.	Está dispuestos al cambio, es competitivo e innova constantemente',
		opcionB: 'b.	Conversa con su equipo y genera polémica sobre los ajustes de la organización ',
		opcionC: 'c.	Comunica la visión con la fue fundada la compañía y no se adapta al mercado ',
	},
	pregunta2: {
		pregunta: '2.	¿Cómo se atreve un líder a generar el cambio en la organización?',
		opcionA: 'a.	Se adapta a los requerimientos y evolución del mercado para crear nuevas estrategias  ',
		opcionB: 'b.	Delega sus responsabilidades y se concentra en lo que ocurre en la industria ',
		opcionC: 'c.	Escucha constantemente a su equipo y se queja de los cambios en el mercado',
	},
	pregunta3: {
		pregunta: '3.	¿Cuáles son las acciones que debe generar un líder transformador?',
		opcionA: 'a.	Realiza preguntas que contribuyen a dar soluciones, impulsa la creatividad y comprueba suposiciones',
		opcionB: 'b.	Dar soluciones inmediatas sin escuchar a su equipo',
		opcionC: 'c.	Comparte información para que su equipo la revise cuando tenga oportunidad',
	},
	pregunta4: {
		pregunta: '4.	¿Por qué un líder debe ser consciente de los sesgos cognitivos?',
		opcionA: 'a.	Porque así evita nublar su perspectiva y la toma decisiones incorrectas, mantiene apertura por conocer la mayor cantidad de información que le permita comprender la situación',
		opcionB: 'b.	Porque de lo contrario abordaría solo los temas que domina evitando conversaciones que lo confronten',
		opcionC: 'c.	Porque evitaría asumir que cualquier tema que pueda escuchar lo puede entender sin ningún problema',
	},
	pregunta5: {
		pregunta: '5.	¿Para qué debe incorporar un líder nuevas estrategias en su gestión?',
		opcionA: 'a.	Para tomar decisiones agiles y objetivas centradas en los resultados de su equipo ',
		opcionB: 'b.	Para revisar con el equipo sus metas personales ',
		opcionC: 'c.	Para disminuir los indicadores de la compañía ',
	},
	pregunta6: {
		pregunta: '6.	¿Por qué un líder debe trabajar en sus creencias limitantes?',
		opcionA: 'a.	Porque debe estar dispuesto a replantear constantemente su opinión y aprender del error',
		opcionB: 'b.	Porque debe consolidar a su equipo con personas con gustos similares',
		opcionC: 'c.	Porque las creencias limitantes son una cualidad para desarrollar a su equipo',
	},
	pregunta7: {
		pregunta: '7.	¿Qué acciones impulsan a los líderes transformadores a ser disruptivos?',
		opcionA: 'a.	Replicar estrategias año con año y estar concentrados en el core del negocio',
		opcionB: 'b.	Conocer otros negocios, generar nuevas ideas y concentrarse en labores que empujen a los equipos a desarrollar cambios significativos en la organización ',
		opcionC: 'c.	Trabajar en los propósitos de la compañía y estar apegados a estrategias conservadoras',
	},
	pregunta8: {
		pregunta: '8.	¿Cómo puede un líder romper paradigmas en la organización?',
		opcionA: 'a.	Realiza cambios constantes y castiga los errores de su equipo ',
		opcionB: 'b.	Explora la tecnología, aprende del error y reestructura los procesos para enfrentarse a cualquier realidad ',
		opcionC: 'c.	Trabaja porque todas sus tareas sean perfectas ',
	},
	pregunta9: {
		pregunta: '9.	¿Cómo ayuda el autoconocimiento a un líder transformador? ',
		opcionA: 'a.	Reconoce sus puntos quiebre, es receptivo y busca formación que le ayuda a lograr resultados',
		opcionB: 'b.	Demuestra con sus resultados que está preparado y no requiere mayor formación',
		opcionC: 'c.	Cuestiona la capacitación que le brinda la compañía ya que no tiene tiempo para consumirla ',
	},
	pregunta10: {
		pregunta: '10.	¿Cuál es el beneficio de desarrollar líderes transformadores? ',
		opcionA: 'a.	Impulsar a la compañía al logro de resultados en tiempos de cambio',
		opcionB: 'b.	Incrementar los objetivos de todos los empleados semestralmente',
		opcionC: 'c.	Adaptarnos a la tecnología sin cambiar procesos de servicio',
	},
};

export default questionario;
