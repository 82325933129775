import React from 'react';
import { Grid, Container } from "@material-ui/core";
import img1 from '../../../../../resources/images/broshure/seccion20/img1.svg';


export default function Seccion20() {

    // return <section id="seccion20" className='wow fadeInUp' data-wow-delay='.2s' data-wow-offset='0' data-wow-duration='.5s'>
    return <section id="seccion20" className='center wow fadeInUp' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
        <Container maxWidth="lg" >
            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                    <div className="center">
                        <img src={img1} alt="img" className='mb30 size' />
                    </div>
                </Grid>
            </Grid>
        </Container>
    </section>
}