import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Container } from "@material-ui/core";
import ValidateUser from "../../../../../components/ValidateUser";
import Header from "../../../../../components/Header";
import Footer from "../../../../../components/Footer";
import SpeakersConferencias from "./SpeakersConferencias";
import { top, } from "../../../../../resources/js/functions";
import {ejectutivos, jefaturasyCoordinadores} from '../informacion'
import "./styles.scss";

export default function VideoTutorial() {
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    top();
  }, []);

  const url = params.id === 'ejecutivos' ? ejectutivos.tutorial.urlVideoTutorial : jefaturasyCoordinadores.tutorial.urlVideoTutorial

  return (
    <>
      <ValidateUser>
        <Header />
        <div id="videoTutorial">
          <Container maxWidth="lg">
            <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={3}>
              <Grid item lg={12} md={12} xs={12}>
                <div className="cursor" onClick={() => navigate(-1)}>{'< Regresar'}</div>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <div className="titulos">{params.id === 'ejecutivos' ? `Tutorial: ${ejectutivos.tutorial.titulo}` : `Tutorial: ${jefaturasyCoordinadores.tutorial.titulo}`}</div>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                {params.id === 'ejecutivos' ?
                  <p>{ejectutivos.tutorial.descripcion}</p> :
                  <p>{jefaturasyCoordinadores.tutorial.descripcion}</p> 
                }
              </Grid>
              <Grid item lg={8} md={12} xs={12}>
                <div className="contenedor-streaming">
                  <iframe title="streaming" src={url} allow="autoplay; fullscreen" frameBorder="0" allowFullScreen className="estilo-streaming"></iframe>
                </div>
              </Grid>
              <SpeakersConferencias />
              <Grid item lg={12} md={12} xs={12} className='center'>
                <div className="cursor" onClick={() => navigate(-1)}>{'< Regresar'}</div>
              </Grid>
            </Grid>
          </Container>
          <Footer />
        </div>
      </ValidateUser>
    </>
  );
}
